// JS
import "../../global.js"

// IMAGE
import TAG from "../../tag.png"

// COMPONENT
import HeaderComponent from "../Header/Header"
import MenuComponent from "../SideMenu/SideMenu"
import BreadcrumbComponent from "../Breadcrumb/Breadcrumb"

// LIBRARY
import Swal from "sweetalert2"
import { toast } from "react-toastify"
import ReactLoading from "react-loading"
import React, { Component } from "react"
import { Slide } from "react-slideshow-image"
import Cookies from "universal-cookie"
import Pagination from "react-js-pagination"

// CSS
import "./style.css"
import "../../bootstrap.css"
import "react-slideshow-image/dist/styles.css"
import "bootstrap-daterangepicker/daterangepicker.css"

const cookies = new Cookies()
toast.configure()
let month_array = [
  "",
  "ม.ค.",
  "ก.พ.",
  "มี.ค.",
  "เม.ย.",
  "พ.ค.",
  "มิ.ย.",
  "ก.ค.",
  "ส.ค.",
  "ก.ย.",
  "ต.ค.",
  "พ.ย.",
  "ธ.ค.",
]

export default class ExternalParcelScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      modal_detail: false,
      modal_filter: false,
      modal_photo: false,
      search: false,
      filter: false,
      photo_big: "",
      slider: [],
      photo_array: [],

      data: null,

      token: cookies.get("token") ? "token " + cookies.get("token") : "",

      filter_date: "",
      filter_agency: "all",
      filter_project: "all",
      filter_status: "all",

      data_search: "",

      data: null,
      detail: null,
      project:
        cookies.get("user_profile").agency_list &&
        cookies.get("user_profile").agency_list
          ? cookies.get("user_profile").agency_list
          : null,
      agency: null,

      index: 0,
      page: 1,
    }
  }
  componentDidMount() {
    this.get_package_external()
    var user = cookies.get("user_profile")
    if (user.role_data.role_name === "system_admin") {
      this.get_Project()
    } else {
      this.state.project.push({
        agency_id: cookies.get("user_profile").agency_id,
        agency_name: cookies.get("user_profile").agency_name,
        project_id: cookies.get("user_profile").project_id,
        project_name: cookies.get("user_profile").project_name,
      })
    }
  }

  get_Project() {
    this.setState({ loading: true })
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
    }
    fetch(global.api + "v1/project/all", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ project: result.data.data })
          this.setState({ loading: false })
        } else {
          switch (result.data.status) {
            case "This Page is not available":
              this.setState({ project: [] })
              break
            default:
              this.alert("info", "แจ้งเตือน", "เซสชันหมดอายุ")
              cookies.remove("token")
              window.location.href = "/"
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.setState({ loading: false })
      })
  }

  get_Agency() {
    this.setState({ loading: true })
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
      body: JSON.stringify({
        project_id: this.state.filter_project,
      }),
    }
    fetch(global.api + "v1/project/agency", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ agency: result.data.data })
          this.setState({ loading: false })
        } else {
          switch (result.data.status) {
            case "This Page is not available":
              this.setState({ agency: [] })
              break
            default:
              this.alert("info", "แจ้งเตือน", "เซสชันหมดอายุ")
              cookies.remove("token")
              window.location.href = "/"
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.setState({ loading: false })
      })
  }

  get_package_external() {
    this.setState({ loading: true })

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
    }
    fetch(
      global.api + "v1/external-package/page/" + this.state.page,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ data: result.data })
          this.setState({ loading: false })
        } else {
          switch (result.data.status) {
            case "This Page is not available":
              this.setState({ data: [] })
              break
            default:
              this.alert("info", "แจ้งเตือน", "เซสชันหมดอายุ")
              cookies.remove("token")
              window.location.href = "/"
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.setState({ loading: false })
      })
  }

  handlePageChange(pageNumber) {
    this.setState({ page: pageNumber })
    if (this.state.search) {
      setTimeout(() => {
        this.search()
      }, 10)
    } else if (this.state.filter) {
      setTimeout(() => {
        this.filter()
      }, 10)
    } else {
      setTimeout(() => {
        this.get_package_external()
      }, 10)
    }
  }

  get_Detail(id, index) {
    this.setState({ loading: true, index: index })
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
    }
    fetch(global.api + "v1/external-package/detail/" + id, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          let slider = []

          for (let sl of result.data.data.picture) {
            slider.push({
              url: sl,
            })
          }
          this.setState({ detail: result.data.data, slider: slider })
          this.setState({ modal_detail: true })

          this.setState({ loading: false })
        } else {
          switch (result.data.status) {
            case "This Page is not available":
              this.setState({ data: [] })
              break
            default:
              this.alert("info", "แจ้งเตือน", "เซสชันหมดอายุ")
              cookies.remove("token")
              window.location.href = "/"
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        console.log(e)
        this.setState({ loading: false })
      })
  }

  filter() {
    this.setState({ loading: true, filter: true })
    let status =
      this.state.filter_status === "1"
        ? 1
        : this.state.filter_status === "2"
        ? 2
        : this.state.filter_status === "3"
        ? 3
        : ""

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
      body: JSON.stringify({
        rec_in_date: this.state.filter_date,
        agency_id: this.state.filter_agency,
        project_id: this.state.filter_project,
        status: status,
      }),
    }
    fetch(
      global.api + "v1/external-package/filter/page/" + this.state.page,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ data: result.data })
          this.setState({ loading: false })
        } else {
          switch (result.data.message) {
            case "This Page is not available":
              this.alert("info", "แจ้งเตือน", "ไม่พบหน้า")
              break
            case "External Package is not found":
              this.setState({ data: null })
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.setState({ loading: false })
      })
  }

  search() {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
      body: JSON.stringify({
        search: this.state.data_search,
      }),
    }
    fetch(
      global.api + "v1/external-package/page/search/" + this.state.page,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          if (result.data.data.length === 0) {
            this.alert("info", "แจ้งเตือน", "ค้นหาไม่พบ")
            this.setState({ data_search: "" })
          } else {
            this.setState({ data: result.data })
          }
          this.setState({ loading: false })
        } else {
          switch (result.data.message) {
            case "This Page is not available":
              this.alert("info", "แจ้งเตือน", "ไม่พบหน้า")
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.setState({ loading: false })
      })
  }

  alert(icon, title, detail) {
    Swal.fire({
      icon: icon,
      title: title,
      text: detail,
      confirmButtonText: "ตกลง",
    })
  }

  render() {
    return (
      <div className="full bg-contain">
        {this.state.loading && (
          <div className="loading">
            <ReactLoading
              type={"spinningBubbles"}
              color="#fff"
              height={48}
              width={48}
            />
          </div>
        )}
        <div className="menu-container">
          <MenuComponent />
          <div className="w-100">
            <BreadcrumbComponent page1="พัสดุภายนอก" page2="" />
            <HeaderComponent header="พัสดุภายนอก" report="true" />
            {/* เมนู ฟิลเตอร์  */}
            <div className="bg-gray px-3 pt-3 w-100">
              <div className="row d-flex mx-0 justify-content-between">
                <div className="d-flex row mx-0">
                  {/* ค้นหา */}
                  <div className="mx-2 mb-3">
                    <input
                      className="form-control bg-white"
                      type="search"
                      placeholder="เลขพัสดุ, ชื่อผู้รับ, ชื่อผู้ส่ง"
                      value={this.state.data_search}
                      onChange={(e) => {
                        this.setState({ data_search: e.target.value })
                        if (e.target.value === "") {
                          this.setState({
                            page: 1,
                            filter_agency: "all",
                            filter_project: "all",
                            filter_status: "all",
                            search: false,
                            filter: false,
                            data_search: "",
                          })
                          setTimeout(() => {
                            this.get_package_external()
                          }, 10)
                        }
                      }}
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          this.setState({
                            page: 1,
                            search: true,
                          })
                          setTimeout(() => {
                            this.search()
                          }, 10)
                        }
                      }}
                    ></input>
                  </div>
                </div>
                <div className="d-flex row mx-0">
                  {/* ตัวกรองโครงการ */}
                  <div className="mx-2 mb-3">
                    <button
                      className="btn btn-outline-primary bg-white "
                      onClick={() => {
                        this.setState({ modal_filter: true })
                      }}
                    >
                      <span className="icon mr-2">{"\uf1de"}</span>
                      ตัวกรองโครงการ
                    </button>
                    <div
                      class={this.state.modal_filter ? "bg-modal" : ""}
                      onClick={() => {
                        this.setState({ modal_filter: false })
                      }}
                    ></div>
                    {/* modal */}
                    <div
                      class={
                        this.state.modal_filter
                          ? "card border-0 modal-filter p-3 mt-3"
                          : "d-none"
                      }
                    >
                      {/* โครงการ */}
                      <div class="row my-2">
                        <div class="col-4">โครงการ:</div>
                        <div class="col-8">
                          <select
                            className="form-control"
                            value={this.state.filter_project}
                            onChange={(e) => {
                              this.setState({
                                filter_project: e.target.value,
                                filter_agency: "all",
                              })
                              setTimeout(() => {
                                this.get_Agency()
                              }, 10)
                            }}
                          >
                            <option value="all" selected disabled>
                              -- เลือกโครงการ --
                            </option>
                            {this.state.project &&
                              this.state.project.map((item, index) => (
                                <option value={item.project_id} key={index}>
                                  {item.project_name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      {/* หน่วยงาน */}
                      <div class="row my-2">
                        <div class="col-4">หน่วยงาน:</div>
                        <div class="col-8">
                          <select
                            className="form-control"
                            value={this.state.filter_agency}
                            onChange={(e) => {
                              this.setState({ filter_agency: e.target.value })
                            }}
                          >
                            <option value="all" selected disabled>
                              -- เลือกหน่วยงาน --
                            </option>
                            {this.state.agency &&
                              this.state.agency.map((item, index) => (
                                <option value={item.agency_id} key={index}>
                                  {item.agency_name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <hr class="my-1" />
                      {/* ปุ่ม */}
                      <div class="row my-2">
                        <div class="col-4"></div>
                        <div class="col-8 d-flex">
                          <button
                            className="btn btn-secondary mr-2 w-50"
                            onClick={() => {
                              this.setState({
                                filter_date: "",
                                filter_agency: "all",
                                filter_project: "all",
                                filter_status: "all",
                                page: 1,
                                search: false,
                                filter: false,
                                data_search: "",
                              })

                              setTimeout(() => {
                                this.get_package_external()
                              }, 10)

                              this.setState({
                                modal_filter: false,
                              })
                            }}
                          >
                            รีเซ็ท
                          </button>
                          <button
                            className="btn btn-primary ml-2 w-50"
                            onClick={() => {
                              this.setState({
                                filter_status: "all",
                                page: 1,
                                data_search: "",
                                search: false,
                              })

                              setTimeout(() => {
                                this.filter()
                              }, 10)

                              this.setState({
                                modal_filter: false,
                              })
                            }}
                          >
                            <span className="icon mr-2">{"\uf1de"}</span>
                            ยืนยัน
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* สถานะ */}
                  <div className="mx-2 mb-3">
                    <select
                      className="form-control"
                      value={this.state.filter_status}
                      onChange={(e) => {
                        this.setState({
                          filter_status: e.target.value,
                          page: 1,
                        })
                        setTimeout(() => {
                          this.filter()
                        }, 10)
                      }}
                    >
                      <option value="all" selected>
                        แสดงทุกสถานะ
                      </option>
                      <option value="1">รอขึ้นรถ</option>
                      <option value="2">ขึ้นรถแล้ว</option>
                      <option value="3">รับแล้ว</option>
                    </select>
                  </div>
                  {/* วันที่ */}
                  <div className="mx-2 mb-3 w-135px">
                    <input
                      className="form-control "
                      type="date"
                      value={this.state.filter_date}
                      onChange={(e) => {
                        this.setState({ filter_date: e.target.value, page: 1 })
                        setTimeout(() => {
                          this.filter()
                        }, 10)
                      }}
                    ></input>
                  </div>
                </div>
              </div>
            </div>
            {/* ตาราง */}
            {this.state.data && this.state.data.data.length === 0 ? (
              <div className="w-100 p-3">
                <div className="table-responsive">
                  <table className="table design-table ">
                    <thead>
                      <tr>
                        <th className="text-left">วันเวลานำเข้า</th>
                        <th className="text-left">เลขพัสดุ</th>
                        <th className="text-left">โครงการที่รับ</th>
                        <th className="text-left  ">หน่วยงาน</th>
                        <th className="text-left">ชื่อผู้ส่ง</th>
                        <th className="text-left ">ชื่อผู้รับ</th>
                        <th className="text-left ">แผนก</th>
                        <th className="text-left">ผู้นำเข้า</th>
                        <th className="text-left ">ผู้นำออก</th>
                        <th className="text-center w-150px">สถานะ</th>
                        <th className="text-center w-130px ">รายละเอียด</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan={11} className="text-center">
                          ไม่มีข้อมูล
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div className="w-100 p-3">
                <div className="table-responsive">
                  <table className="table design-table ">
                    <thead>
                      <tr>
                        <th className="text-left">วันเวลานำเข้า</th>
                        <th className="text-left">เลขพัสดุ</th>
                        <th className="text-left">โครงการที่รับ</th>
                        <th className="text-left  ">หน่วยงาน</th>
                        <th className="text-left">ชื่อผู้ส่ง</th>
                        <th className="text-left ">ชื่อผู้รับ</th>
                        <th className="text-left ">แผนก</th>
                        <th className="text-left">ผู้นำเข้า</th>
                        <th className="text-left ">ผู้นำออก</th>
                        <th className="text-center w-150px">สถานะ</th>
                        <th className="text-center w-130px ">รายละเอียด</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data &&
                        this.state.data.data.map((item, index) => (
                          <tr key={index}>
                            <td>
                              {item.rec_in_date
                                ? item.rec_in_date.slice(8, 10) +
                                  " " +
                                  month_array[
                                    Number(item.rec_in_date.slice(5, 7))
                                  ] +
                                  " " +
                                  (Number(item.rec_in_date.slice(0, 4)) + 543)
                                    .toString()
                                    .slice(-2) +
                                  " , " +
                                  item.rec_in_time.slice(0, 5) +
                                  " น."
                                : " - "}
                            </td>
                            <td>
                              {item.track_number ? item.track_number : "-"}
                            </td>
                            <td>
                              {item.send_project_name
                                ? item.send_project_name
                                : "-"}
                            </td>
                            <td>
                              {item.send_agency_name
                                ? item.send_agency_name
                                : "-"}
                            </td>
                            <td>{item.send_name ? item.send_name : "-"}</td>
                            <td>
                              {item.rec_in_receiver_name
                                ? item.rec_in_receiver_name
                                : "-"}
                            </td>
                            <td>
                              {item.rec_in_receiver_department
                                ? item.rec_in_receiver_department
                                : "-"}
                            </td>
                            <td>
                              {item.rec_data_in_by ? item.rec_data_in_by : "-"}
                            </td>
                            <td>
                              {item.rec_data_out_by
                                ? item.rec_data_out_by
                                : "-"}
                            </td>
                            <td className="text-center">
                              {item.status === 1 && (
                                <span className="status bg-yellow">
                                  รอขึ้นรถ
                                </span>
                              )}
                              {item.status === 2 && (
                                <span className="status bg-red">
                                  ขึ้นรถแล้ว
                                </span>
                              )}
                              {item.status === 3 && (
                                <span className="status bg-green">รับแล้ว</span>
                              )}
                            </td>
                            <td className="text-center">
                              <button
                                className="btn btn-link"
                                onClick={() => {
                                  this.get_Detail(item.ext_pkg_id, index)
                                }}
                              >
                                <span className="icon mr-2">{"\uf06e"}</span>
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}

            {/* pagination */}
            {this.state.data && this.state.data.data.length !== 0 && (
              <div className="d-flex justify-content-between row mx-0 w-100 px-3">
                <div>
                  แสดง {this.state.data.end_index} จาก {this.state.data.count}{" "}
                </div>
                <div className="d-flex">
                  <button
                    className="btn-circle-white mx-1"
                    onClick={() => {
                      this.handlePageChange(1)
                    }}
                  >
                    <span className="icon">{"\uf100"}</span>
                  </button>

                  <Pagination
                    activePage={this.state.page}
                    itemsCountPerPage={10}
                    totalItemsCount={this.state.data.count}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange.bind(this)}
                    prevPageText={<span className="icon">{"\uf104"}</span>}
                    nextPageText={<span className="icon">{"\uf105"}</span>}
                    hideFirstLastPages={true}
                  />

                  <button
                    className="btn-circle-white mx-1"
                    onClick={() => {
                      this.handlePageChange(this.state.data.page_number)
                    }}
                  >
                    <span className="icon">{"\uf101"}</span>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* modal */}
        <div className={this.state.modal_detail ? "show-modal" : "hide-modal"}>
          <div
            className="w-100 h-100 position-absolute"
            onClick={() => {
              this.setState({ modal_detail: false })
            }}
          ></div>
          <div
            className={
              this.state.modal_detail ? "box-modal p-4" : "hide-box-modal p-4"
            }
          >
            {/* ปุ่มปิด */}
            <div className="w-100">
              <button
                className="btn btn-link d-block d-md-none ml-auto"
                onClick={() => {
                  this.setState({ modal_detail: false })
                }}
              >
                <span className="icon mr-2 text-24">{"\uf00d"}</span>
              </button>
            </div>
            <div className="slide-container mb-3">
              <div className="position-track-agency">
                <div className="track-agency">
                  <span className="text-white">
                    โครงการ{" "}
                    {this.state.detail && this.state.detail.send_project_name}{" "}
                    {this.state.detail && this.state.detail.send_agency_name}
                  </span>
                </div>
              </div>

              {this.state.slider.length === 0 ? (
                <div className="no-image">
                  <span className="icon-no-image"> {"\uf302"}</span>
                </div>
              ) : (
                <>
                  <Slide
                    arrows={this.state.slider.length === 1 ? false : true}
                    indicators={true}
                    pauseOnHover={true}
                  >
                    {this.state.slider.map((slideImage, index) => (
                      <div className="each-slide" key={index}>
                        <div className="position-track-agency">
                          <div className="track-agency">
                            <span className="text-white">
                              โครงการ{" "}
                              {this.state.detail &&
                                this.state.detail.send_project_name}{" "}
                              {this.state.detail &&
                                this.state.detail.send_agency_name}
                            </span>
                          </div>
                        </div>
                        <div
                          style={{
                            backgroundImage: `url(${slideImage.url})`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            height: 254,
                            borderRadius: 16,
                          }}
                          onClick={() => {
                            for (let i = 0; i < this.state.slider.length; i++) {
                              let img_url = this.state.slider[i].url
                              this.state.photo_array.push(img_url)
                            }

                            setTimeout(() => {
                              this.setState({
                                modal_photo: true,
                                photo_big: slideImage.url,
                              })
                            }, 1)
                          }}
                        ></div>
                      </div>
                    ))}
                  </Slide>
                </>
              )}
            </div>
            {/* วันที่นำเข้า - เวลานำเข้า */}
            <div className="d-flex justify-content-around align-items-center">
              <div className="w-33 text-center">
                <span className="icon text-secondary mr-2 text-24">
                  {"\uf133"}
                </span>
                <br />
                {this.state.detail && (
                  <b>
                    {this.state.detail.rec_in_date.slice(8, 10) +
                      " " +
                      month_array[
                        Number(this.state.detail.rec_in_date.slice(5, 7))
                      ] +
                      " " +
                      (Number(this.state.detail.rec_in_date.slice(0, 4)) + 543)
                        .toString()
                        .slice(-2)}
                  </b>
                )}
                <br />
                <span className="text-secondary">วันที่นำเข้า</span>
              </div>
              <div className="line-secondary"></div>
              <div className="w-33 text-center">
                <span className="icon text-secondary mr-2 text-24">
                  {"\uf017"}
                </span>
                <br />
                <b>
                  {this.state.detail &&
                    this.state.detail.rec_in_time.slice(0, 5)}{" "}
                  น.
                </b>
                <br />
                <span className="text-secondary">เวลานำเข้า</span>
              </div>
              <div className="line-secondary"></div>
              <div className="w-33 text-center">
                <span className="icon text-secondary mr-2 text-24">
                  {"\uf03a"}
                </span>
                <br />
                <b>{this.state.detail && this.state.detail.track_number}</b>
                <br />
                <span className="text-secondary">ลำดับ</span>
              </div>
            </div>
            <hr />
            {/* สถานะ */}
            <div className="d-flex justify-content-between align-items-center my-2">
              <span className="text-secondary">สถานะ:</span>
              {this.state.detail && this.state.detail.status === 1 && (
                <span className="status bg-yellow">รอขึ้นรถ</span>
              )}
              {this.state.detail && this.state.detail.status === 2 && (
                <span className="status bg-red">ขึ้นรถแล้ว</span>
              )}
              {this.state.detail && this.state.detail.status === 3 && (
                <span className="status bg-green">รับแล้ว</span>
              )}
            </div>
            {/* โครงการที่รับ */}
            <div className="d-flex justify-content-between mb-2">
              <span className="text-secondary">โครงการที่รับ:</span>
              <span className="w-75 text-right">
                {this.state.detail && this.state.detail.send_project_name
                  ? this.state.detail.send_project_name
                  : "-"}
              </span>
            </div>
            {/* หน่วยงาน */}
            <div className="d-flex justify-content-between mb-2">
              <span className="text-secondary">หน่วยงาน:</span>
              <span className="w-75 text-right">
                {this.state.detail && this.state.detail.send_agency_name
                  ? this.state.detail.send_agency_name
                  : "-"}
              </span>
            </div>
            <hr />
            {/* ชื่อผู้ส่ง */}
            <div className="d-flex justify-content-between my-2">
              <span className="text-secondary">ชื่อผู้ส่ง:</span>
              <span className="w-75 text-right">
                {this.state.detail && this.state.detail.send_name
                  ? this.state.detail.send_name
                  : "-"}
              </span>
            </div>
            {/* ชื่อผู้รับ */}
            <div className="d-flex justify-content-between my-2">
              <span className="text-secondary">ชื่อผู้รับ:</span>
              <span className="w-75 text-right">
                {this.state.detail && this.state.detail.rec_in_receiver_name
                  ? this.state.detail.rec_in_receiver_name
                  : "-"}
              </span>
            </div>
            {/* แผนกผู้รับ */}
            <div className="d-flex justify-content-between my-2">
              <span className="text-secondary">แผนกผู้รับ:</span>
              <span className="w-75 text-right">
                {this.state.detail &&
                this.state.detail.rec_in_receiver_department
                  ? this.state.detail.rec_in_receiver_department
                  : "-"}
              </span>
            </div>
            {/* หมายเหตุ */}
            <div className="d-flex justify-content-between my-2">
              <span className="text-secondary">หมายเหตุ:</span>
              <span className="w-75 text-right">
                {this.state.detail && this.state.detail.rec_in_remark
                  ? this.state.detail.rec_in_remark
                  : "-"}
              </span>
            </div>
            {/* ผู้นำเข้า */}
            <div className="d-flex justify-content-between my-2">
              <span className="text-secondary">ชื่อผู้นำเข้า:</span>
              <span className="w-75 text-right">
                {this.state.detail && this.state.detail.rec_data_in_by
                  ? this.state.detail.rec_data_in_by
                  : "-"}
              </span>
            </div>
            <hr />
            {/* วันเวลานำขึ้นรถ */}
            <div className="d-flex justify-content-between mb-2">
              <span className="text-secondary">วันเวลานำขึ้นรถ:</span>
              <span className="w-75 text-right">
                {this.state.detail &&
                this.state.detail.status !== 1 &&
                this.state.detail &&
                this.state.detail.date_into_car
                  ? this.state.detail.date_into_car.slice(8, 10) +
                    " " +
                    month_array[
                      Number(this.state.detail.date_into_car.slice(5, 7))
                    ] +
                    " " +
                    (Number(this.state.detail.date_into_car.slice(0, 4)) + 543)
                      .toString()
                      .slice(-2) +
                    " , " +
                    this.state.detail.time_into_car.slice(0, 5) +
                    " น."
                  : " - "}
              </span>
            </div>
            {/* พนักงานขับรถ */}
            <div className="d-flex justify-content-between my-2">
              <span className="text-secondary">พนักงานขับรถ:</span>
              <span className="w-75 text-right">
                {this.state.detail && this.state.detail.driver_name
                  ? this.state.detail.driver_name
                  : "-"}
              </span>
            </div>
            {/* ลายเซ็นพนักงานขับรถ */}
            <div className="d-flex justify-content-between my-2">
              <span className="text-secondary">ลายเซ็นพนักงานขับรถ:</span>
              {this.state.detail && this.state.detail.signature && (
                <img
                  className="tag-name"
                  alt="tag"
                  src={this.state.detail ? this.state.detail.signature : "TAG"}
                ></img>
              )}
            </div>
            {/* หมายเหตุ */}
            <div className="d-flex justify-content-between my-2">
              <span className="text-secondary">หมายเหตุ:</span>
              <span className="w-75 text-right">
                {this.state.detail && this.state.detail.remark_into_car
                  ? this.state.detail.remark_into_car
                  : "-"}
              </span>
            </div>
            {/* ผู้นำออก */}
            <div className="d-flex justify-content-between mb-2">
              <span className="text-secondary">ชื่อผู้นำออก:</span>
              <span className="w-75 text-right">
                {this.state.detail && this.state.detail.rec_data_out_by
                  ? this.state.detail.rec_data_out_by
                  : "-"}
              </span>
            </div>
            <hr />
            {/* วันที่รับพัสดุ */}
            <div className="d-flex justify-content-between my-2">
              <span className="text-secondary">วันเวลารับพัสดุ:</span>
              <span className="w-75 text-right">
                {this.state.detail && this.state.detail.rec_out_receiver_date
                  ? this.state.detail.rec_out_receiver_date.slice(8, 10) +
                    " " +
                    month_array[
                      Number(
                        this.state.detail.rec_out_receiver_date.slice(5, 7)
                      )
                    ] +
                    " " +
                    Number(this.state.detail.rec_out_receiver_date.slice(0, 4))
                      .toString()
                      .slice(-2) +
                    " , " +
                    this.state.detail.rec_out_receiver_time +
                    " น."
                  : " - "}
              </span>
            </div>
            {/* ชื่อผู้รับพัสดุ */}
            <div className="d-flex justify-content-between my-2">
              <span className="text-secondary">ชื่อผู้รับพัสดุ:</span>
              <span className="w-75 text-right">
                {this.state.detail && this.state.detail.rec_out_receiver_name
                  ? this.state.detail.rec_out_receiver_name
                  : "-"}
              </span>
            </div>
            {/* แผนก */}
            <div className="d-flex justify-content-between my-2">
              <span className="text-secondary">แผนก:</span>
              <span className="w-75 text-right">
                {this.state.detail &&
                this.state.detail.rec_out_receiver_department
                  ? this.state.detail.rec_out_receiver_department
                  : "-"}
              </span>
            </div>
            {/* หมายเหตุ */}
            <div className="d-flex justify-content-between my-2">
              <span className="text-secondary">หมายเหตุ:</span>
              <span className="w-75 text-right">
                {this.state.detail && this.state.detail.rec_out_receiver_remark
                  ? this.state.detail.rec_out_receiver_remark
                  : "-"}
              </span>
            </div>
            {/* ผู้ตรวจสอบ */}
            <div className="d-flex justify-content-between my-2">
              <span className="text-secondary">ผู้ตรวจสอบ:</span>
              <span className="w-75 text-right">
                {this.state.detail && this.state.detail.rec_out_receiver_checker
                  ? this.state.detail.rec_out_receiver_checker
                  : "-"}
              </span>
            </div>
          </div>
        </div>
        {/* modal photo */}
        <div className={this.state.modal_photo ? "show-modal" : "hide-modal"}>
          <div
            className="w-100 h-100 position-absolute"
            onClick={() => {
              this.setState({ modal_photo: false, photo_array: [] })
            }}
          ></div>
          <div
            className={
              this.state.modal_photo ? "box-modal p-4" : "hide-box-modal p-4"
            }
          >
            {/* ปุ่มปิด */}
            <div className="d-flex justify-content-between">
              <h3>
                <b>ภาพหลักฐาน</b>
              </h3>
              <button
                className="btn btn-link ml-auto"
                onClick={() => {
                  this.setState({ modal_photo: false, photo_array: [] })
                }}
              >
                <span className="icon mr-2 text-24">{"\uf00d"}</span>
              </button>
            </div>
            <div className="w-100 mt-4">
              {this.state.photo_big ? (
                <img
                  className="w-100 img-responsive"
                  style={{ borderRadius: 10 }}
                  alt="tag"
                  src={this.state.photo_big}
                ></img>
              ) : null}
            </div>

            <div className="d-flex mt-4 row justify-content-center">
              {this.state.photo_array &&
                this.state.photo_array.map((img, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      this.setState({
                        photo_big: img,
                      })
                    }}
                  >
                    <img
                      className="out-list-image m-1"
                      alt="tag"
                      src={img}
                    ></img>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
