// JS
import "../../global.js"

// LIBRARY
import Swal from "sweetalert2"
import { Link } from "react-router"
import LOGO from "./logo-haladiem.png"
import Cookies from "universal-cookie"
import React, { Component } from "react"

// CSS
import "./style.css"
import "../../bootstrap.css"

const cookies = new Cookies()

export default class LoginScreen extends Component {
  constructor(props) {
    super(props)
    this.state = { email: "", password: "", remember: false }
  }

  componentDidMount() {
    let token = cookies.get("token")
    if (token) {
      window.location.href = "/in-and-out-ward"
    }
  }

  alert(icon, title, detail) {
    Swal.fire({
      icon: icon,
      title: title,
      text: detail,
      confirmButtonText: "ตกลง",
    })
  }

  Login = () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        email: this.state.email,
        password: this.state.password,
        remember: this.state.remember,
        access_by: "website",
      }),
    }
    fetch(global.api + "v1/login", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          cookies.set("token", result.data.token)
          cookies.set("user_profile", JSON.stringify(result.data.detail))

          window.location.href = "/in-and-out-ward"
        } else {
          switch (result.data.message) {
            case "email is none":
              this.alert("info", "แจ้งเตือน", "กรุณากรอกอีเมล")
              break
            case "password is none":
              this.alert("info", "แจ้งเตือน", "กรุณากรอกรหัสผ่าน")
              break
            case "Invalid Credentials or activate account":
              this.alert("info", "แจ้งเตือน", "อีเมลหรือรหัสผ่านไม่ถูกต้อง")
              break
            case "Email no access permission":
              this.alert("info", "แจ้งเตือน", "อีเมลไม่ได้รับอนุญาตให้เข้าถึง")
              break
            case "User is not found":
              this.alert("info", "แจ้งเตือน", "ไม่พบอีเมลนี้ในระบบ")
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              break
          }
        }
      })
  }
  render() {
    return (
      <div className="full center bg-contain">
        <div className="col-xl-2 col-lg-1 col-md-1 col-sm-1 col-1" />
        <div className="col-xl-8 col-lg-10 col-md-10 col-sm-10 col-10">
          <div className="card ">
            <div className="row mx-0">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 text-center bg-primary p-5 rounded-left d-none d-sm-none d-xl-block d-lg-block d-md-block">
                <div class="w-100 h-100 d-flex justify-content-center align-items-center">
                  <img className="logo" alt="logo" src={LOGO}></img>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 text-center bg-primary p-5 rounded-top d-block d-xl-none d-lg-none d-md-none">
                <div class="w-100 h-100 d-flex justify-content-center align-items-center">
                  <img className="logo" alt="logo" src={LOGO}></img>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 p-5">
                <h3>
                  <b>ลงชื่อเข้าใช้</b>
                </h3>
                <span className="text-secondary mb-3">
                  ตรวจสอบ ออกรายงาน สำหรับพนักงาน
                </span>
                <br />
                <span>อีเมล:</span>
                <br />
                <input
                  className="form-control"
                  type="email"
                  id="email"
                  name="name"
                  placeholder="example@gmail.com"
                  onKeyUp={(e) => {
                    this.setState({ email: e.target.value })
                  }}
                ></input>
                <br />
                <span>รหัสผ่าน:</span>
                <br />
                <input
                  className="form-control"
                  type="password"
                  id="password"
                  name="password"
                  placeholder="password"
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      this.Login()
                    } else {
                      this.setState({ password: e.target.value })
                    }
                  }}
                ></input>
                <br />
                <div className="d-flex row justify-content-between mx-0">
                  <div className="w-100">
                    <input
                      className="mr-2"
                      type="checkbox"
                      id="remember"
                      name="remember"
                      onChange={(e) => {
                        this.setState({ remember: e.target.checked })
                      }}
                    ></input>
                    จำฉันไว้ให้อยู่ในระบบ
                  </div>
                  <Link to="/forgot-password" className="button">
                    <span>ลืมรหัสผ่าน?</span>
                  </Link>
                </div>
                {/* <Link to="/in-and-out-ward" className="button"> */}
                <button
                  type="button"
                  className="btn btn-primary w-100 mb-3"
                  onClick={() => {
                    this.Login()
                  }}
                >
                  ลงชื่อเข้าใช้
                </button>
                {/* </Link> */}
                {/* <Link to="/register" className="button">
                  <button type="button" className="btn btn-link w-100">
                    สมัครสมาชิก
                  </button>
                </Link> */}
                {/* <input className="btn btn-primary" type="button">
                  ลงชื่อเข้าใช้
                </input> */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-2 col-lg-1 col-md-1 col-sm-1 col-1" />
      </div>
    )
  }
}
