// JS
import "../../global.js";

// LIBRARY
import { Link } from "react-router";
import { toast } from "react-toastify";
import LOGO from "./logo-haladiem.png";
import Cookies from "universal-cookie";
import React, { Component } from "react";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";

// CSS
import "./style.css";
import "../../bootstrap.css";
import "react-toastify/dist/ReactToastify.css";
import "react-pro-sidebar/dist/css/styles.css";

const cookies = new Cookies();

toast.configure();

export default class BreadcrumbComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullname: cookies.get("user_profile").full_name
        ? cookies.get("user_profile").full_name
        : "",
      sidebar: false,
      token: cookies.get("token") ? "token " + cookies.get("token") : "",
      user_profile: cookies.get("user_profile"),
    };
  }

  componentDidMount() {}

  Logout = async () => {
    const requestOptions = {
      method: "GET",

      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
    };
    fetch(global.api + "v1/logout", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        cookies.remove("token");
        window.location.href = "/";
      });
  };

  render() {
    return (
      <div>
        <div className="breadcrumb-component row mx-0 px-0 position-fixed">
          <div className="col-12 d-flex justify-content-between align-items-center">
            <div className="col-lg-6 align-items-center justify-content-start d-flex">
              <span
                className="d-block d-lg-none d-xl-none mr-3"
                onClick={() => {
                  this.setState({ sidebar: !this.state.sidebar });
                }}
              >
                <span className="icon-brand text-dark">{"\uf0c9"}</span>
              </span>

              <div className="w-100">
                <span className="text-left">{this.props.page1}</span>
                {this.props.page2 && (
                  <span className="text-dark ml-2 mr-2">{" > "}</span>
                )}
                <span className="text-primary text-left">
                  {this.props.page2}
                </span>
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-center">
              {/* <div className="profile-image mr-3"></div> */}
              <span className="mr-3">{this.state.fullname}</span>
              <div className="dropdowns">
                <a href="#" className="dropbtns">
                  <span className="icon-brand text-primary text-14">
                    {"\uf078"}
                  </span>
                </a>
                <div className="dropdowns-content">
                  <a
                    href="#"
                    onClick={() => {
                      this.Logout();
                    }}
                  >
                    <span className="icon">{"\uf30d"}</span> ออกจากระบบ
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ProSidebar
          className="d-block d-lg-none d-xl-none full-screen"
          breakPoint="lg"
          toggled={this.state.sidebar}
          onFocus={() => {
            this.setState({ sidebar: !this.state.sidebar });
          }}
        >
          <Menu iconShape="square" className="pt-5">
            <div className="w-100 text-center mb-4 mt-5">
              <img className="logo-menu" alt="logo" src={LOGO}></img>
            </div>
            {this.state.user_profile &&
              this.state.user_profile.role_data &&
              this.state.user_profile.role_data.role_detail &&
              this.state.user_profile.role_data.role_detail.in_out_ward ===
                "true" && (
                <MenuItem
                  active={window.location.pathname === "/in-and-out-ward"}
                  icon={<span className="icon">{"\uf007"}</span>}
                >
                  <Link to="/in-and-out-ward" className="button">
                    เข้า-ออกวอร์ด
                  </Link>
                </MenuItem>
              )}

            {this.state.user_profile &&
              this.state.user_profile.role_data &&
              this.state.user_profile.role_data.role_detail &&
              this.state.user_profile.role_data.role_detail.internal_package ===
                "true" && (
                <MenuItem
                  active={window.location.pathname === "/internal-parcel"}
                  icon={<span className="icon">{"\uf090"}</span>}
                >
                  <Link to="/internal-parcel" className="button">
                    จดหมายและพัสดุ
                  </Link>
                </MenuItem>
              )}

            {this.state.user_profile &&
              this.state.user_profile.role_data &&
              this.state.user_profile.role_data.role_detail &&
              this.state.user_profile.role_data.role_detail.external_package ===
                "true" && (
                <MenuItem
                  active={window.location.pathname === "/external-parcel"}
                  icon={<span className="icon">{"\uf08b"}</span>}
                >
                  <Link to="/external-parcel" className="button">
                    พัสดุภายนอก
                  </Link>
                </MenuItem>
              )}

            {this.state.user_profile &&
              this.state.user_profile.role_data &&
              this.state.user_profile.role_data.role_detail &&
              this.state.user_profile.role_data.role_detail.in_out_area ===
                "true" && (
                <SubMenu
                  icon={<span className="icon">{"\uf15b"}</span>}
                  title="เข้า-ออกพื้นที่"
                  defaultOpen={
                    window.location.pathname === "/in-and-out-list" ||
                    window.location.pathname === "/card-type"
                  }
                >
                  <MenuItem
                    active={window.location.pathname === "/in-and-out-list"}
                  >
                    <Link to="/in-and-out-list" className="button">
                      รายการเข้า-ออก
                    </Link>
                  </MenuItem>
                  <MenuItem active={window.location.pathname === "/card-type"}>
                    <Link to="/card-type" className="button">
                      จัดการประเภทบัตร
                    </Link>
                  </MenuItem>
                </SubMenu>
              )}

            {this.state.user_profile &&
              this.state.user_profile.role_data &&
              this.state.user_profile.role_data.role_detail &&
              this.state.user_profile.role_data.role_detail.move_things ===
                "true" && (
                <MenuItem
                  active={window.location.pathname === "/carrying"}
                  icon={<span className="icon">{"\uf187"}</span>}
                >
                  <Link to="/carrying" className="button">
                    ขนของเข้า-ออก
                  </Link>
                </MenuItem>
              )}
          </Menu>

          <Menu iconShape="square" className="bottom-0 w-100 ">
            {this.state.user_profile &&
              this.state.user_profile.role_data &&
              this.state.user_profile.role_data.role_detail &&
              this.state.user_profile.role_data.role_detail.role_manage ===
                "true" && (
                <SubMenu
                  icon={<span className="icon">{"\uf023"}</span>}
                  title="ผู้ดูแลเว็บไซต์"
                  defaultOpen={
                    window.location.pathname === "/admin" ||
                    window.location.pathname === "/role-web" ||
                    window.location.pathname === "/role-mobile"
                  }
                >
                  <MenuItem active={window.location.pathname === "/admin"}>
                    <Link to="/admin" className="button">
                      ผู้ดูแลเว็บไซต์
                    </Link>
                  </MenuItem>
                  <MenuItem active={window.location.pathname === "/role-web"}>
                    <Link to="/role-web" className="button">
                      บทบาทเว็บไซต์
                    </Link>
                  </MenuItem>
                  <MenuItem
                    active={window.location.pathname === "/role-mobile"}
                  >
                    <Link to="/role-mobile" className="button">
                      บทบาทโมบายแอป
                    </Link>
                  </MenuItem>
                </SubMenu>
              )}

            {this.state.user_profile &&
              this.state.user_profile.role_data &&
              this.state.user_profile.role_data.role_detail &&
              this.state.user_profile.role_data.role_detail.user_manage ===
                "true" && (
                <MenuItem
                  active={window.location.pathname === "/user"}
                  icon={<span className="icon">{"\uf234"}</span>}
                >
                  <Link to="/user" className="button">
                    ผู้ใช้งาน
                  </Link>
                </MenuItem>
              )}
          </Menu>
        </ProSidebar>
      </div>
    );
  }
}
