// JS
import "../../../global.js"

// COMPONENT
import HeaderComponent from "../../Header/Header"
import MenuComponent from "../../SideMenu/SideMenu"
import BreadcrumbComponent from "../../Breadcrumb/Breadcrumb"

// LIBRARY
import Swal from "sweetalert2"
import { toast } from "react-toastify"
import Cookies from "universal-cookie"
import ReactLoading from "react-loading"
import React, { Component } from "react"
import Pagination from "react-js-pagination"
import { Slide } from "react-slideshow-image"
import DateRangePicker from "react-bootstrap-daterangepicker"

// CSS
import "./style.css"
import "../../../bootstrap.css"
import "react-slideshow-image/dist/styles.css"
import "bootstrap-daterangepicker/daterangepicker.css"

const cookies = new Cookies()
toast.configure()

export default class CardTypeScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modal_add: false,
      modal_edit: false,

      token: cookies.get("token") ? "token " + cookies.get("token") : "",
      data: null,
      card_list: null,
      card_id: "",
      card_name: "",
      project: "",
      agency: "",

      index: -1,
      subindex: -1,
      page: 1,

      project_all_list:
        cookies.get("user_profile").agency_list &&
        cookies.get("user_profile").agency_list
          ? cookies.get("user_profile").agency_list
          : null,
      agency_all_list: [],
    }
  }

  componentDidMount() {
    this.get_Card_Type()
    var user = cookies.get("user_profile")
    if (user.role_data.role_name === "system_admin") {
      this.get_Project()
    } else {
      this.state.project_all_list.push({
        agency_id: cookies.get("user_profile").agency_id,
        agency_name: cookies.get("user_profile").agency_name,
        project_id: cookies.get("user_profile").project_id,
        project_name: cookies.get("user_profile").project_name,
      })
    }
    setTimeout(() => {
      console.log(this.state.project_all_list);
    }, 50);
  }

  alert(icon, title, detail) {
    Swal.fire({
      icon: icon,
      title: title,
      text: detail,
      confirmButtonText: "ตกลง",
    })
  }
  get_Card_Type() {
    this.setState({ loading: true })
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
    }
    fetch(global.api + "v1/in-out-area/page/" + this.state.page, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ data: result.data })
          this.setState({ loading: false })
        } else {
          switch (result.data.status) {
            case "This Page is not available":
              this.setState({ data: [] })
              break
            default:
              this.alert("info", "แจ้งเตือน", "เซสชันหมดอายุ")
              cookies.remove("token")
              window.location.href = "/"
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.setState({ loading: false })
      })
  }
  get_Project() {
    this.setState({ loading: true })
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    }
    fetch(global.api + "v1/project/all", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ project_all_list: result.data.data })
          this.setState({ loading: false })
        } else {
          switch (result.data.message) {
            case "":
              break
            default:
              this.alert("info", "แจ้งเตือน", "เซสชันหมดอายุ")
              cookies.remove("token")
              window.location.href = "/"
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.setState({ loading: false })
      })
  }
  get_Agency(id) {
    this.setState({ loading: true })
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        project_id: id,
      }),
    }
    fetch(global.api + "v1/project/agency", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ agency_all_list: result.data.data })
          this.setState({ loading: false })
        } else {
          switch (result.data.message) {
            case "":
              break
            default:
              this.alert("info", "แจ้งเตือน", "เซสชันหมดอายุ")
              cookies.remove("token")
              window.location.href = "/"
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.setState({ loading: false })
      })
  }

  handlePageChange(pageNumber) {
    this.setState({ page: pageNumber, index: -1, card_list: null })
    setTimeout(() => {
      this.get_Card_Type()
    }, 1)
  }

  Create = () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
      body: JSON.stringify({
        agency_id: this.state.agency,
        type_card_name: this.state.card_name,
      }),
    }
    fetch(global.api + "v1/in-out-area/create", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.clear_form()
          this.alert("success", "สำเร็จ", "เพิ่มประเภทบัตรแล้ว")
          this.setState({ page: 1 })
          this.get_Card_Type()
        } else {
          switch (result.data.message) {
            case "agency id is none":
              this.alert("info", "แจ้งเตือน", "กรุณาเลือกโครงการและหน่วยงาน")
              break
            case "type card name is none":
              this.alert("info", "แจ้งเตือน", "กรุณากรอกชื่อประเภทบัตร")
              break
            case "type card name is duplicate":
              this.alert("info", "แจ้งเตือน", "มีชื่อประเภทบัตรนี้ในระบบแล้ว")
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.setState({ loading: false })
      })
  }

  Edit = () => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
      body: JSON.stringify({
        type_card_id: this.state.card_id,
        type_card_name: this.state.card_name,
      }),
    }
    fetch(global.api + "v1/in-out-area/update", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.clear_form()
          this.alert("success", "สำเร็จ", "แก้ไขประเภทบัตรแล้ว")
          this.setState({ page: 1 })
          this.get_Card_Type()
        } else {
          switch (result.data.message) {
            case "agency id is none":
              this.alert("info", "แจ้งเตือน", "กรุณาเลือกโครงการและหน่วยงาน")
              break
            case "type card name is none":
              this.alert("info", "แจ้งเตือน", "กรุณากรอกชื่อประเภทบัตร")
              break
            case "type card name is duplicate":
              this.alert("info", "แจ้งเตือน", "มีชื่อประเภทบัตรนี้ในระบบแล้ว")
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.setState({ loading: false })
      })
  }

  Delete = (card) => {
    Swal.fire({
      icon: "info",
      title: "ลบประเภทบัตร",
      text: "ท่านแน่ใจว่าต้องการลบประเภทบัตร “" + card.type_card_name + "”",
      showCancelButton: true,
      confirmButtonText: "ลบข้อมูล",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.isConfirmed) {
        const requestOptions = {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
            Authorization: this.state.token,
          },
          accept: "application/json",
        }
        fetch(
          global.api + "v1/in-out-area/delete/" + card.type_card_id,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            if (result.status) {
              this.setState({ loading: false, page: 1 })
              this.alert("success", "แจ้งเตือน", "ลบประเภทบัตรสำเร็จ")
              this.clear_form()
              this.get_Card_Type()
            } else {
              switch (result.data.message) {
                case "":
                  break
                default:
                  this.alert("info", "แจ้งเตือน", "เซสชันหมดอายุ")
                  cookies.remove("token")
                  window.location.href = "/"
                  break
              }
              this.setState({ loading: false })
            }
          })
          .catch((e) => {
            this.setState({ loading: false })
          })
      }
    })
  }
  clear_form() {
    this.setState({
      modal_add: false,
      modal_edit: false,
      card_list: null,
      card_id: "",
      card_name: "",
      project: "",
      agency: "",

      index: -1,
      subindex: -1,
      page: 1,
    })
  }
  render() {
    return (
      <div className="full bg-contain">
        {this.state.loading && (
          <div className="loading">
            <ReactLoading
              type={"spinningBubbles"}
              color="#fff"
              height={48}
              width={48}
            />
          </div>
        )}
        <div className="menu-container">
          <MenuComponent />
          <div className="w-100">
            <BreadcrumbComponent
              page1="เข้า-ออกพื้นที่"
              page2="จัดการประเภทบัตร"
            />
            <HeaderComponent header="จัดการประเภทบัตร" report="false" />{" "}
            {/* เมนู ฟิลเตอร์  */}
            <div className="bg-gray px-3 pt-3 w-100">
              <div className="row d-flex mx-0 justify-content-between">
                <div className="d-flex row mx-0"></div>
                <div className="d-flex row mx-0 mx-2 mb-3">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      this.setState({ modal_add: true })
                    }}
                  >
                    + เพิ่มประเภทบัตร
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5">
                {/* ตาราง */}
                <div className="w-100 p-3">
                  <div className="table-responsive">
                    <table className="table design-table">
                      <thead>
                        <tr>
                          <th>ชื่อโครงการ</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.data &&
                          this.state.data.data.map((item, index) => (
                            <>
                              <tr>
                                <td
                                  className=" pointer justify-content-center"
                                  onClick={() => {
                                    this.setState({
                                      index:
                                        this.state.index === index ? -1 : index,
                                      subindex: -1,
                                      card_list: null,
                                    })
                                  }}
                                >
                                  {item.project_name}
                                </td>
                                <td
                                  className="text-right text-secondary pointer"
                                  onClick={() => {
                                    this.setState({
                                      index:
                                        this.state.index === index ? -1 : index,
                                      subindex: -1,
                                      card_list: null,
                                    })
                                  }}
                                >
                                  {item.count_agency} หน่วยงาน
                                  <span className="icon ml-3 text-primary text-24">
                                    {"\uf105"}
                                  </span>
                                </td>
                              </tr>
                              {index === this.state.index && (
                                <>
                                  {item.agency_list.map(
                                    (agency, agency_index) => (
                                      <tr
                                        class={
                                          this.state.subindex === agency_index
                                            ? "bg-primary text-white"
                                            : "bg-light"
                                        }
                                      >
                                        <td
                                          colSpan={2}
                                          className="pl-4 pointer"
                                          onClick={() => {
                                            this.setState({
                                              card_list: agency.type_card_list,
                                              subindex: agency_index,
                                            })
                                          }}
                                        >
                                          {agency.agency_name}
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </>
                              )}
                            </>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                {/* pagination */}
                {this.state.data && (
                  <div className="d-flex justify-content-between row mx-0 w-100 px-3">
                    <div>
                      <span>
                        แสดง {this.state.data.end_index} จาก{" "}
                        {this.state.data.count} รายการ
                      </span>
                    </div>
                    <div className="d-flex">
                      <button
                        className="btn-circle-white mx-1"
                        onClick={() => {
                          this.handlePageChange(1)
                        }}
                      >
                        <span className="icon">{"\uf100"}</span>
                      </button>
                      <Pagination
                        activePage={this.state.page}
                        itemsCountPerPage={10}
                        totalItemsCount={this.state.data.count}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange.bind(this)}
                        prevPageText={<span className="icon">{"\uf104"}</span>}
                        nextPageText={<span className="icon">{"\uf105"}</span>}
                        hideFirstLastPages={true}
                      />
                      <button
                        className="btn-circle-white mx-1"
                        onClick={() => {
                          this.handlePageChange(this.state.data.page_number)
                        }}
                      >
                        <span className="icon">{"\uf101"}</span>
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-7 col-xl-7">
                {/* ตาราง */}
                <div className="w-100 p-3">
                  <div className="table-responsive">
                    <table className="table design-table">
                      <thead>
                        <tr>
                          <th>ชื่อประเภท</th>
                          <th className="text-center w-150px">จัดการ</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.card_list &&
                        this.state.card_list.length !== 0 ? (
                          this.state.card_list.map((item, index) => (
                            <tr>
                              <td>{item.type_card_name}</td>
                              <td className=" w-150px">
                                <div className="d-flex justify-content-center">
                                  <button
                                    className="btn btn-link"
                                    onClick={() => {
                                      this.setState({
                                        card_id: item.type_card_id,
                                        card_name: item.type_card_name,
                                        project: this.state.data
                                          ? this.state.data.data[
                                              this.state.index
                                            ].project_name
                                          : "",
                                        agency: this.state.data
                                          ? this.state.data.data[
                                              this.state.index
                                            ].agency_list[this.state.subindex]
                                              .agency_name
                                          : "",
                                        modal_edit: true,
                                      })
                                    }}
                                  >
                                    <span className="icon mr-2">
                                      {"\uf304"}
                                    </span>
                                  </button>
                                  <button
                                    className="btn btn-link text-danger"
                                    onClick={() => {
                                      this.Delete(item)
                                    }}
                                  >
                                    <span className="icon mr-2">
                                      {"\uf1f8"}
                                    </span>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={2}>ไม่มีบัตร</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* modal add */}
        <div className={this.state.modal_add ? "show-modal" : "hide-modal"}>
          <div
            className="w-100 h-100 position-absolute"
            onClick={() => {
              this.clear_form()
            }}
          ></div>
          <div
            className={
              this.state.modal_add ? "box-modal p-4" : "hide-box-modal p-4"
            }
          >
            <div className="d-flex justify-content-between">
              <h3>
                <b>เพิ่มประเภทบัตร</b>
              </h3>
              <button
                className="btn btn-link d-block d-md-none"
                onClick={() => {
                  this.clear_form()
                }}
              >
                <span className="icon mr-2 text-24">{"\uf00d"}</span>
              </button>
            </div>
            {/* โครงการ */}
            <div className="row mt-3">
              <div className="col-12 col-sm-12 col-md-4 col-xl-4 col-lg-4">
                <span>โครงการ:</span>
              </div>
              <div className="col-12 col-sm-12 col-md-8 col-xl-8 col-lg-8">
                <select
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ project: e.target.value })
                    this.get_Agency(e.target.value)
                  }}
                  value={this.state.project}
                >
                  <option value="">-- เลือกโครงการ --</option>
                  {this.state.project_all_list.map((project, index) => (
                    <option value={project.project_id}>
                      {project.project_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {/* หน่วยงาน */}
            <div className="row mt-3">
              <div className="col-12 col-sm-12 col-md-4 col-xl-4 col-lg-4">
                <span>หน่วยงาน:</span>
              </div>
              <div className="col-12 col-sm-12 col-md-8 col-xl-8 col-lg-8">
                <select
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ agency: e.target.value })
                  }}
                  value={this.state.agency}
                >
                  <option value="">-- เลือกหน่วย --</option>
                  {this.state.agency_all_list.map((agency, index) => (
                    <option value={agency.agency_id}>
                      {agency.agency_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {/* ชื่อประเภทบัตร */}
            <div className="row mt-4">
              <div className="col-12 col-sm-12 col-md-4 col-xl-4 col-lg-4">
                <span>ชื่อประเภทบัตร:</span>
              </div>
              <div className="col-12 col-sm-12 col-md-8 col-xl-8 col-lg-8">
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ card_name: e.target.value })
                  }}
                  value={this.state.card_name}
                ></input>
              </div>
            </div>

            {/* ปุ่ม */}
            <div className="row">
              <div className="col-12 col-sm-12 col-md-8 col-xl-8 col-lg-8 ml-auto">
                <div className="row ">
                  <div className="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 mt-3">
                    <button
                      className="btn btn-secondary w-100"
                      onClick={() => {
                        this.clear_form()
                      }}
                    >
                      ยกเลิก
                    </button>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 mt-3">
                    <button
                      className="btn btn-primary w-100"
                      onClick={() => {
                        this.Create()
                      }}
                    >
                      เพิ่มบัตร
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* modal edit */}
        <div className={this.state.modal_edit ? "show-modal" : "hide-modal"}>
          <div
            className="w-100 h-100 position-absolute"
            onClick={() => {
              this.clear_form()
            }}
          ></div>
          <div
            className={
              this.state.modal_edit ? "box-modal p-4" : "hide-box-modal p-4"
            }
          >
            <div className="d-flex justify-content-between">
              <h3>
                <b>แก้ไขประเภทบัตร</b>
              </h3>
              <button
                className="btn btn-link d-block d-md-none"
                onClick={() => {
                  this.clear_form()
                }}
              >
                <span className="icon mr-2 text-24">{"\uf00d"}</span>
              </button>
            </div>
            {/* โครงการ */}
            <div className="row mt-3">
              <div className="col-12 col-sm-12 col-md-4 col-xl-4 col-lg-4">
                <span>โครงการ:</span>
              </div>
              <div className="col-12 col-sm-12 col-md-8 col-xl-8 col-lg-8">
                <input
                  type="text"
                  className="form-control"
                  value={this.state.project}
                  readOnly
                ></input>
              </div>
            </div>
            {/* หน่วยงาน */}
            <div className="row mt-3">
              <div className="col-12 col-sm-12 col-md-4 col-xl-4 col-lg-4">
                <span>หน่วยงาน:</span>
              </div>
              <div className="col-12 col-sm-12 col-md-8 col-xl-8 col-lg-8">
                <input
                  type="text"
                  className="form-control"
                  value={this.state.agency}
                  readOnly
                ></input>
              </div>
            </div>
            {/* ชื่อประเภทบัตร */}
            <div className="row mt-4">
              <div className="col-12 col-sm-12 col-md-4 col-xl-4 col-lg-4">
                <span>ชื่อประเภทบัตร:</span>
              </div>
              <div className="col-12 col-sm-12 col-md-8 col-xl-8 col-lg-8">
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ card_name: e.target.value })
                  }}
                  value={this.state.card_name}
                ></input>
              </div>
            </div>

            {/* ปุ่ม */}
            <div className="row">
              <div className="col-12 col-sm-12 col-md-8 col-xl-8 col-lg-8 ml-auto">
                <div className="row ">
                  <div className="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 mt-3">
                    <button
                      className="btn btn-secondary w-100"
                      onClick={() => {
                        this.clear_form()
                      }}
                    >
                      ยกเลิก
                    </button>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 mt-3">
                    <button
                      className="btn btn-primary w-100"
                      onClick={() => {
                        this.Edit()
                      }}
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
