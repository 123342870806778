// JS
import "../../global.js";

// LIBRARY
import { Link } from "react-router";
import { toast } from "react-toastify";
import LOGO from "./logo-haladiem.png";
import React, { Component } from "react";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import Cookies from "universal-cookie";

// CSS
import "./style.css";
import "../../bootstrap.css";
import "react-toastify/dist/ReactToastify.css";
import "react-pro-sidebar/dist/css/styles.css";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";

const cookies = new Cookies();
toast.configure();

export default class MenuComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_profile: cookies.get("user_profile"),
      inout:
        window.location.pathname === "/in-and-out-list" ||
        window.location.pathname === "/card-type",
      admin:
        window.location.pathname === "/admin" ||
        window.location.pathname === "/role-web" ||
        window.location.pathname === "/role-mobile",
    };
  }

  componentDidMount() {}

  render() {
    return (
      <ProSidebar breakPoint="lg" toggled={false}>
        <Menu iconShape="square">
          <div className="w-100 text-center my-4">
            <img className="logo-menu" alt="logo" src={LOGO}></img>
          </div>
          {this.state.user_profile &&
            this.state.user_profile.role_data &&
            this.state.user_profile.role_data.role_detail &&
            this.state.user_profile.role_data.role_detail.in_out_ward ===
              "true" && (
              <MenuItem
                active={window.location.pathname === "/in-and-out-ward"}
                icon={<span className="icon">{"\uf007"}</span>}
              >
                <Link to="/in-and-out-ward" className="button">
                  เข้า-ออกวอร์ด
                </Link>
              </MenuItem>
            )}

          {this.state.user_profile &&
            this.state.user_profile.role_data &&
            this.state.user_profile.role_data.role_detail &&
            this.state.user_profile.role_data.role_detail.internal_package ===
              "true" && (
              <MenuItem
                active={window.location.pathname === "/internal-parcel"}
                icon={<span className="icon">{"\uf090"}</span>}
              >
                <Link to="/internal-parcel" className="button">
                  จดหมายและพัสดุ
                </Link>
              </MenuItem>
            )}

          {this.state.user_profile &&
            this.state.user_profile.role_data &&
            this.state.user_profile.role_data.role_detail &&
            this.state.user_profile.role_data.role_detail.external_package ===
              "true" && (
              <MenuItem
                active={window.location.pathname === "/external-parcel"}
                icon={<span className="icon">{"\uf08b"}</span>}
              >
                <Link to="/external-parcel" className="button">
                  พัสดุภายนอก
                </Link>
              </MenuItem>
            )}

          {this.state.user_profile &&
            this.state.user_profile.role_data &&
            this.state.user_profile.role_data.role_detail &&
            this.state.user_profile.role_data.role_detail.in_out_area ===
              "true" && (
              <SubMenu
                icon={<span className="icon">{"\uf15b"}</span>}
                title="เข้า-ออกพื้นที่"
                defaultOpen={
                  window.location.pathname === "/in-and-out-list" ||
                  window.location.pathname === "/card-type"
                }
                open={this.state.inout}
                onClick={() => {
                  this.setState({ admin: false, inout: !this.state.inout });
                }}
              >
                <MenuItem
                  active={window.location.pathname === "/in-and-out-list"}
                >
                  <Link to="/in-and-out-list" className="button">
                    รายการเข้า-ออก
                  </Link>
                </MenuItem>
                <MenuItem active={window.location.pathname === "/card-type"}>
                  <Link to="/card-type" className="button">
                    จัดการประเภทบัตร
                  </Link>
                </MenuItem>
              </SubMenu>
            )}

          {this.state.user_profile &&
            this.state.user_profile.role_data &&
            this.state.user_profile.role_data.role_detail &&
            this.state.user_profile.role_data.role_detail.move_things ===
              "true" && (
              <MenuItem
                active={window.location.pathname === "/carrying"}
                icon={<span className="icon">{"\uf187"}</span>}
              >
                <Link to="/carrying" className="button">
                  ขนของเข้า-ออก
                </Link>
              </MenuItem>
            )}
        </Menu>

        <Menu iconShape="square" className="mt-auto">
          {this.state.user_profile &&
            this.state.user_profile.role_data &&
            this.state.user_profile.role_data.role_detail &&
            this.state.user_profile.role_data.role_detail.role_manage ===
              "true" && (
              <SubMenu
                icon={<span className="icon">{"\uf023"}</span>}
                title="ผู้ดูแลเว็บไซต์"
                defaultOpen={
                  window.location.pathname === "/admin" ||
                  window.location.pathname === "/role-web" ||
                  window.location.pathname === "/role-mobile"
                }
                open={this.state.admin}
                onClick={() => {
                  this.setState({ inout: false, admin: !this.state.admin });
                }}
              >
                <MenuItem active={window.location.pathname === "/admin"}>
                  <Link to="/admin" className="button">
                    ผู้ดูแลเว็บไซต์
                  </Link>
                </MenuItem>
                <MenuItem active={window.location.pathname === "/role-web"}>
                  <Link to="/role-web" className="button">
                    บทบาทเว็บไซต์
                  </Link>
                </MenuItem>
                <MenuItem active={window.location.pathname === "/role-mobile"}>
                  <Link to="/role-mobile" className="button">
                    บทบาทโมบายแอป
                  </Link>
                </MenuItem>
              </SubMenu>
            )}

          {this.state.user_profile &&
            this.state.user_profile.role_data &&
            this.state.user_profile.role_data.role_detail &&
            this.state.user_profile.role_data.role_detail.user_manage ===
              "true" && (
              <MenuItem
                active={window.location.pathname === "/user"}
                icon={<span className="icon">{"\uf234"}</span>}
              >
                <Link to="/user" className="button">
                  ผู้ใช้งาน
                </Link>
              </MenuItem>
            )}
        </Menu>
      </ProSidebar>
    );
  }
}
