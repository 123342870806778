// JS
import "../../global.js";

// LIBRARY
import { Link } from "react-router";
import { toast } from "react-toastify";
import React, { Component } from "react";

// CSS
import "./style.css";
import "../../bootstrap.css";
import "react-toastify/dist/ReactToastify.css";

toast.configure();

export default class RegisterScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}
  Register = () => {
    toast.success("สร้างบัญชีผู้ใช้สำเร็จ!", { autoClose: 3000 });
  };
  render() {
    return (
      <div className="full center bg-contain">
        <div className="col-xl-4 col-lg-3 col-md-3 col-sm-2 col-1" />
        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-8 col-10">
          <div className="card ">
            <div className="card-body p-5">
              {/* HEADER */}
              <h2 className="card-title">
                <b>สมัครสมาชิก</b>
              </h2>
              <p className="text-secondary">สร้างบัญชีผู้ใช้งาน</p>
              {/* USERNAME INPUT */}
              <span>ชื่อผู้ใช้:</span>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Username"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </div>
              {/* EMAIL INPUT  */}
              <span>อีเมล:</span>
              <div className="mb-3">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  aria-label="Email"
                  aria-describedby="basic-addon1"
                />
              </div>
              {/* PASSWORD INPUT  */}
              <span>รหัสผ่าน:</span>
              <div className="mb-3">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  aria-label="Password"
                  aria-describedby="basic-addon1"
                />
              </div>
              {/* REPEAT PASSWORD INPUT */}
              <span>ยืนยันรหัสผ่าน:</span>
              <div className="mb-3">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Repeat Password"
                  aria-label="Repeat Password"
                  aria-describedby="basic-addon1"
                />
              </div>
              {/* BUTTON */}
              <div className="row mt-4 mb-3">
                <div className="col-12">
                  <Link to="/" className="button">
                    <button
                      type="button"
                      className="btn btn-primary w-100"
                      onClick={() => this.Register()}
                    >
                      สร้างบัญชีผู้ใช้
                    </button>
                  </Link>
                </div>
              </div>
              <Link to="/" className="button">
                <button type="button" className="btn btn-link w-100">
                  เข้าสู่ระบบ
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-3 col-md-3 col-sm-2 col-1" />
      </div>
    );
  }
}
