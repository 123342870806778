// JS
import "../../global.js"

// COMPONENT
import HeaderComponent from "../Header/Header"
import MenuComponent from "../SideMenu/SideMenu"
import BreadcrumbComponent from "../Breadcrumb/Breadcrumb"

// LIBRARY
import Swal from "sweetalert2"
import { toast } from "react-toastify"
import Cookies from "universal-cookie"
import React, { Component } from "react"
import Pagination from "react-js-pagination"

// CSS
import "./style.css"
import "../../bootstrap.css"

const cookies = new Cookies()
toast.configure()

export default class UserScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      modal_add: false,
      modal_edit: false,
      modal_password: false,
      isVisible: false,

      token: cookies.get("token") ? "token " + cookies.get("token") : "",

      data: null,
      page: 1,

      fullname: "",
      email: "",
      phone: "",
      agency_list: [],
      role: "",
      username: "",
      verify_pin: "",
      password: "",
      project: "",
      agency: "",
      data_search: "",

      project_all_list:
        cookies.get("user_profile").agency_list &&
        cookies.get("user_profile").agency_list
          ? cookies.get("user_profile").agency_list
          : null,

      agency_all_list: [],
      role_list: [],
    }
  }

  componentDidMount() {
    this.get_All_User()
    this.get_Role_Mobile()

    var user = cookies.get("user_profile")
    if (user.role_data.role_name === "system_admin") {
      this.get_Project()
    } else {
      this.state.project_all_list.push({
        agency_id: cookies.get("user_profile").agency_id,
        agency_name: cookies.get("user_profile").agency_name,
        project_id: cookies.get("user_profile").project_id,
        project_name: cookies.get("user_profile").project_name,
      })
    }
  }

  alert(icon, title, detail) {
    Swal.fire({
      icon: icon,
      title: title,
      text: detail,
      confirmButtonText: "ตกลง",
    })
  }

  handlePageChange(pageNumber) {
    this.setState({ page: pageNumber })
    setTimeout(() => {
      this.get_All_User()
    }, 10)
  }

  get_All_User() {
    this.setState({ loading: true })
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
    }
    fetch(global.api + "v1/user/mobile/page/" + this.state.page, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ data: result.data })
          this.setState({ loading: false })
        } else {
          switch (result.data.message) {
            case "":
              break
            default:
              this.alert("info", "แจ้งเตือน", "เซสชันหมดอายุ")
              cookies.remove("token")
              window.location.href = "/"
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.setState({ loading: false })
      })
  }

  get_Role_Mobile() {
    this.setState({ loading: true })
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
    }
    fetch(global.api + "v1/user/role/mobile/all", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ role_list: result.data.data })
          this.setState({ loading: false })
        } else {
          switch (result.data.message) {
            case "":
              break
            default:
              this.alert("info", "แจ้งเตือน", "เซสชันหมดอายุ")
              cookies.remove("token")
              window.location.href = "/"
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.setState({ loading: false })
      })
  }

  get_Project() {
    this.setState({ loading: true })
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    }
    fetch(global.api + "v1/project/all", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ project_all_list: result.data.data })
          this.setState({ loading: false })
        } else {
          switch (result.data.message) {
            case "":
              break
            default:
              this.alert("info", "แจ้งเตือน", "เซสชันหมดอายุ")
              cookies.remove("token")
              window.location.href = "/"
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.setState({ loading: false })
      })
  }

  get_Agency(id) {
    this.setState({ loading: true, agency: "" })
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        project_id: id,
      }),
    }
    fetch(global.api + "v1/project/agency", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ agency_all_list: result.data.data })
          this.setState({ loading: false })
        } else {
          switch (result.data.message) {
            case "":
              break
            default:
              this.alert("info", "แจ้งเตือน", "เซสชันหมดอายุ")
              cookies.remove("token")
              window.location.href = "/"
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.setState({ loading: false })
      })
  }

  clear_form() {
    this.setState({
      modal_add: false,
      modal_edit: false,
      modal_password: false,
      fullname: "",
      email: "",
      phone: "",
      verify_pin: "",
      agency_list: [],
      role: "",
      username: "",
      password: "",
      project: "",
      agency: "",
      agency_all_list: [],
    })
  }

  getDeteil = (user) => {
    this.get_Agency(user.project_id)

    console.log("user", user)

    this.setState({
      user_id: user.user_id,
      modal_edit: true,
      fullname: user.full_name,
      email: user.username,
      verify_pin: user.verify_pin,
      phone: user.phone,
      agency_list: user.agency_list,
      role: user.role_data.role_id,
      project: user.project_id,
      agency: user.agency_id,
    })
  }

  getDetailPassword = (user) => {
    this.setState({
      modal_password: true,
    })
    setTimeout(async () => {
      await this.setState({
        user_id: user.user_id,
        verify_pin: user.verify_pin,
        username: user.username,
      })
    }, 10)
  }
  gen_Password = () => {
    let result = ""
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
    var charactersLength = characters.length
    for (var i = 0; i < 6; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    this.setState({ password: result })
  }
  Create = () => {
    this.setState({ loading: true })

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
      body: JSON.stringify({
        email: this.state.email,
        password: this.state.password,
        first_name: this.state.fullname,
        verify_pin: this.state.verify_pin,
        phone: this.state.phone,
        agency_id: this.state.agency,
        role_id: this.state.role,
        picture: "",
      }),
    }
    fetch(global.api + "v1/user/create", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.clear_form()
          this.alert("success", "สำเร็จ", "เพิ่มผู้ใช้งานแล้ว")
          this.setState({ page: 1 })
          window.location.href = "/user"
        } else {
          switch (result.data.message) {
            case "first name is none":
              this.alert("info", "แจ้งเตือน", "กรุณากรอกชื่อ")
              break
            case "email is duplicate":
              this.alert("info", "แจ้งเตือน", "อีเมลนี้ถูกใช้งานแล้ว")
              break
            case "email is none":
              this.alert("info", "แจ้งเตือน", "กรุณากรอกอีเมล")
              break
            case "phone is none":
              this.alert("info", "แจ้งเตือน", "กรุณากรอกเบอร์โทรศัพท์")
              break
            case "password is none":
              this.alert("info", "แจ้งเตือน", "กรุณากดสุ่มรหัสผ่าน")
              break
            case "role id not found":
              this.alert("info", "แจ้งเตือน", "กรุณาเลือกบทบาท")
              break
            case "agency id not found":
              this.alert("info", "แจ้งเตือน", "กรุณาเลือกหน่วยงาน")
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        console.log("e", e)
        this.setState({ loading: false })
      })
  }
  Edit = () => {
    this.setState({ loading: true })

    let agency = []
    for (let ag of this.state.agency_list) {
      if (ag.agency_id) {
        agency.push(ag.agency_id)
      } else {
        agency.push(ag)
      }
    }

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
      body: JSON.stringify({
        user_id: this.state.user_id,
        email: this.state.email,
        password: this.state.password,
        verify_pin: this.state.verify_pin,
        first_name: this.state.fullname,
        phone: this.state.phone,
        agency_id: this.state.agency,
        role_id: this.state.role,
        picture: "",
      }),
    }
    fetch(global.api + "v1/user/update", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.clear_form()
          this.alert("success", "สำเร็จ", "แก้ไขผู้ใช้งานแล้ว")
          this.setState({ page: 1 })
          window.location.href = "/user"
        } else {
          switch (result.data.message) {
            case "first name is none":
              this.alert("info", "แจ้งเตือน", "กรุณากรอกชื่อ")
              break
            case "email is none":
              this.alert("info", "แจ้งเตือน", "กรุณากรอกอีเมล")
              break
            case "phone is none":
              this.alert("info", "แจ้งเตือน", "กรุณากรอกเบอร์โทรศัพท์")
              break
            case "password is none":
              this.alert("info", "แจ้งเตือน", "กรุณากดสุ่มรหัสผ่าน")
              break
            case "role id not found":
              this.alert("info", "แจ้งเตือน", "กรุณาเลือกบทบาท")
              break
            case "Agency is not found":
              this.alert("info", "แจ้งเตือน", "กรุณาเลือกหน่วยงาน")
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.setState({ loading: false })
      })
  }
  EditPassword = () => {
    this.setState({ loading: true })
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
      body: JSON.stringify({
        user_id: this.state.user_id,
        verify_pin: this.state.verify_pin,
        password: this.state.password,
        username: this.state.username,
      }),
    }
    fetch(global.api + "v1/user/update-password", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.clear_form()

          this.alert("success", "สำเร็จ", "เปลี่ยนรหัสผ่านแล้ว")

          this.setState({ page: 1 })
          window.location.href = "/user"
        } else {
          switch (result.data.message) {
            case "password is none":
              this.alert("info", "แจ้งเตือน", "กรุณากดสุ่มรหัสผ่าน")
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.setState({ loading: false })
      })
  }

  Delete = (user) => {
    Swal.fire({
      icon: "info",
      title: "ลบผู้ใช้งาน",
      text: "ท่านแน่ใจว่าต้องการลบผู้ใช้งาน “" + user.full_name + "”",
      showCancelButton: true,
      confirmButtonText: "ลบข้อมูล",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.isConfirmed) {
        const requestOptions = {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
            Authorization: this.state.token,
          },
          accept: "application/json",
        }
        fetch(global.api + "v1/user/delete/" + user.user_id, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            if (result.status) {
              this.setState({ loading: false, page: 1 })
              this.alert("success", "แจ้งเตือน", "ลบผู้ใช้งานสำเร็จ")
              this.get_All_User()
            } else {
              switch (result.data.message) {
                case "":
                  break
                default:
                  this.alert("info", "แจ้งเตือน", "เซสชันหมดอายุ")
                  cookies.remove("token")
                  window.location.href = "/"
                  break
              }
              this.setState({ loading: false })
            }
          })
          .catch((e) => {
            this.setState({ loading: false })
          })
      }
    })
  }

  validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  }

  search() {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
      body: JSON.stringify({
        user_type: "mobile",
        data_search: this.state.data_search,
      }),
    }
    fetch(
      global.api + "v1/user/search-user/page/" + this.state.page,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ data: result.data })
          this.setState({ loading: false })
        } else {
          switch (result.data.message) {
            case "This Page is not available":
              this.alert("info", "แจ้งเตือน", "ไม่พบหน้า")
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.setState({ loading: false })
      })
  }

  isVisiblePassword = () => {
    this.setState({ isVisible: !this.state.isVisible })
  }

  render() {
    return (
      <div className="full bg-contain">
        <div className="menu-container">
          <MenuComponent />
          <div className="w-100">
            <BreadcrumbComponent page1="ผู้ใช้งาน" page2="" />
            <HeaderComponent header="ผู้ใช้งาน" report="false" />
            {/* เมนู ฟิลเตอร์  */}
            <div className="bg-gray px-3 pt-3 w-100">
              <div className="row d-flex mx-0 justify-content-between">
                <div className="d-flex row mx-0">
                  {/* ค้นหา */}
                  <div className="mx-2 mb-3">
                    <input
                      className="form-control bg-white"
                      type="search"
                      placeholder="ค้นหา..."
                      value={this.state.data_search}
                      onChange={(e) => {
                        this.setState({ data_search: e.target.value })

                        if (e.target.value === "") {
                          this.setState({ page: 1 })
                          this.get_All_Admin()
                        }
                      }}
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          this.setState({
                            page: 1,
                          })

                          setTimeout(() => {
                            this.search()
                          }, 5)
                        }
                      }}
                    ></input>
                  </div>
                </div>
                <div className="d-flex row mx-0">
                  <div className="mx-2 mb-3">
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        this.setState({ modal_add: true })
                      }}
                    >
                      + เพิ่มผู้ใช้งาน
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* ตาราง */}
            {this.state.data &&
            this.state.data.data &&
            this.state.data.data.length === 0 ? (
              <div className="w-100 p-3">
                <div className="table-responsive">
                  <table className="table design-table">
                    <thead>
                      <tr>
                        <th className="text-left">ชื่อ-นามสกุล</th>
                        <th className="text-left">อีเมล</th>
                        <th className="text-left">หมายเลขโทรศัพท์</th>
                        <th className="text-left">บทบาท</th>
                        <th className="text-center  w-150px">จัดการ</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan={5} className="text-center">
                          ไม่มีข้อมูล
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div className="w-100 p-3">
                <div className="table-responsive">
                  <table className="table design-table">
                    <thead>
                      <tr>
                        <th className="text-left">ชื่อ-นามสกุล</th>
                        <th className="text-left">อีเมล</th>
                        <th className="text-left">หมายเลขโทรศัพท์</th>
                        <th className="text-left">บทบาท</th>
                        <th className="text-center  w-150px">จัดการ</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data &&
                        this.state.data.data &&
                        this.state.data.data.map((user, index) => (
                          <tr key={index}>
                            <td>{user.full_name}</td>
                            <td>{user.username}</td>
                            <td>{user.phone}</td>
                            <td>{user.role_data.role_name}</td>

                            <td className="text-center w-150px">
                              <div className="d-flex justify-content-center">
                                <button
                                  className="btn btn-link"
                                  onClick={() => {
                                    this.getDeteil(user)
                                  }}
                                >
                                  <span className="icon mr-2">{"\uf304"}</span>
                                </button>
                                <button
                                  className="btn btn-link"
                                  onClick={() => {
                                    this.getDetailPassword(user)
                                  }}
                                >
                                  <span className="icon mr-2">{"\uf084"}</span>
                                </button>
                                <button
                                  className="btn btn-link text-danger"
                                  onClick={() => {
                                    this.Delete(user)
                                  }}
                                >
                                  <span className="icon mr-2">{"\uf1f8"}</span>
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}

            {/* pagination */}
            {this.state.data && this.state.data.data.length !== 0 && (
              <div className="d-flex justify-content-between row mx-0 w-100 px-3">
                <div>
                  <span>
                    แสดง {this.state.data.end_index} จาก {this.state.data.count}{" "}
                    รายการ
                  </span>
                </div>
                <div className="d-flex">
                  <button
                    className="btn-circle-white mx-1"
                    onClick={() => {
                      this.handlePageChange(1)
                    }}
                  >
                    <span className="icon">{"\uf100"}</span>
                  </button>
                  <Pagination
                    activePage={this.state.page}
                    itemsCountPerPage={10}
                    totalItemsCount={this.state.data.count}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange.bind(this)}
                    prevPageText={<span className="icon">{"\uf104"}</span>}
                    nextPageText={<span className="icon">{"\uf105"}</span>}
                    hideFirstLastPages={true}
                  />
                  <button
                    className="btn-circle-white mx-1"
                    onClick={() => {
                      this.handlePageChange(this.state.data.page_number)
                    }}
                  >
                    <span className="icon">{"\uf101"}</span>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* modal add */}
        <div className={this.state.modal_add ? "show-modal" : "hide-modal"}>
          <div
            className="w-100 h-100 position-absolute"
            onClick={() => {
              this.clear_form()
            }}
          ></div>
          <div
            className={
              this.state.modal_add ? "box-modal p-4" : "hide-box-modal p-4"
            }
          >
            <div className="d-flex justify-content-between">
              <h3 className="w-200px">
                <b className="text-left">เพิ่มผู้ใช้งาน</b>
              </h3>

              <button
                className="btn btn-link d-block d-md-none"
                onClick={() => {
                  this.clear_form()
                }}
              >
                <span className="icon mr-2 text-24">{"\uf00d"}</span>
              </button>
            </div>

            {/* ชื่อ-นามสกุล */}
            <div className="row mt-4">
              <div className="col-12 col-sm-4 col-md-4 col-xl-4 col-lg-4">
                <span>ชื่อ-นามสกุล:</span>
              </div>
              <div className="col-12 col-sm-8 col-md-8 col-xl-8 col-lg-8">
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ fullname: e.target.value })
                  }}
                  value={this.state.fullname}
                ></input>
              </div>
            </div>
            {/* อีเมล */}
            <div className="row mt-4">
              <div className="col-12 col-sm-4 col-md-4 col-xl-4 col-lg-4">
                <span>อีเมล:</span>
              </div>
              <div className="col-12 col-sm-8 col-md-8 col-xl-8 col-lg-8">
                <input
                  type="email"
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ email: e.target.value })
                  }}
                  value={this.state.email}
                ></input>
              </div>
            </div>
            {/* เบอร์โทร */}
            <div className="row mt-4">
              <div className="col-12 col-sm-4 col-md-4 col-xl-4 col-lg-4">
                <span>เบอร์โทร:</span>
              </div>
              <div className="col-12 col-sm-8 col-md-8 col-xl-8 col-lg-8">
                <input
                  type="tel"
                  className="form-control"
                  onChange={(e) => {
                    if (e.target.value.match(/^\d+$/)) {
                      this.setState({ phone: e.target.value })
                    }
                  }}
                  value={this.state.phone}
                  maxLength={10}
                ></input>
              </div>
            </div>
            <hr />
            {/* โครงการ */}
            <div className="row mt-3">
              <div className="col-12 col-sm-4 col-md-4 col-xl-4 col-lg-4">
                <span>โครงการ:</span>
              </div>
              <div className="col-12 col-sm-8 col-md-8 col-xl-8 col-lg-8">
                <select
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ project: e.target.value })

                    this.get_Agency(e.target.value)
                  }}
                  value={this.state.project}
                >
                  <option value="">-- เลือกโครงการ --</option>
                  {this.state.project_all_list.map((project, index) => (
                    <option value={project.project_id} key={index}>
                      {project.project_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {/* หน่วยงาน */}
            <div className="row mt-3">
              <div className="col-12 col-sm-4 col-md-4 col-xl-4 col-lg-4">
                <span>หน่วยงาน:</span>
              </div>
              <div className="col-12 col-sm-8 col-md-8 col-xl-8 col-lg-8">
                <select
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ agency: e.target.value })
                  }}
                  value={this.state.agency}
                >
                  <option value="">-- เลือกหน่วย --</option>
                  {this.state.agency_all_list.map((agency, index) => (
                    <option value={agency.agency_id} key={index}>
                      {agency.agency_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {this.state.agency_list &&
              this.state.agency_list.map((agency, index) => (
                <div className="row mt-3" key={index}>
                  <div className="col-12 col-sm-4 col-md-4 col-xl-4 col-lg-4"></div>
                  <div className="col-12 col-sm-8 col-md-8 col-xl-8 col-lg-8">
                    <select
                      className="form-control"
                      onChange={(e) => {
                        this.setState({ project: e.target.value })
                      }}
                      value={this.state.project}
                    >
                      <option value="">-- เลือกหน่วย --</option>
                    </select>
                  </div>
                </div>
              ))}
            <hr />
            {/* บทบาท */}
            <div className="row mt-3">
              <div className="col-12 col-sm-4 col-md-4 col-xl-4 col-lg-4">
                <span>บทบาท:</span>
              </div>
              <div className="col-12 col-sm-8 col-md-8 col-xl-8 col-lg-8">
                <select
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ role: e.target.value })
                  }}
                  value={this.state.role}
                >
                  <option value="">-- เลือกบทบาทผู้ดูแล --</option>
                  {this.state.role_list &&
                    this.state.role_list.map((role, index) => (
                      <option value={role.role_id}>{role.role_name}</option>
                    ))}
                </select>
              </div>
            </div>
            <hr />
            {/* รหัส PIN */}
            <div className="row mt-3">
              <div className="col-12 col-sm-4 col-md-4 col-xl-4 col-lg-4">
                <span>รหัส PIN:</span>
              </div>
              <div className="col-12 col-sm-8 col-md-8 col-xl-8 col-lg-8">
                <input
                  className="form-control"
                  maxLength={6}
                  onChange={(e) => {
                    if (e.target.value.match(/^\d+$/)) {
                      this.setState({ verify_pin: e.target.value })
                    } else {
                      this.setState({ verify_pin: "" })
                    }
                  }}
                  value={this.state.verify_pin}
                ></input>
              </div>
            </div>
            {/* สุ่มรหัสผ่าน */}
            <div className="row mt-4">
              <div className="col-12 col-sm-4 col-md-4 col-xl-4 col-lg-4">
                <span>สุ่มรหัสผ่าน:</span>
              </div>
              <div className="col-12 col-sm-8 col-md-8 col-xl-8 col-lg-8">
                {this.state.password === "" ? (
                  <button
                    className="card w-100 p-3 align-items-center text-center bg-gray"
                    onClick={() => {
                      this.gen_Password()
                    }}
                  >
                    <h5>
                      <b className="text-primary">
                        คลิกที่นี่เพื่อสุ่มรหัสผ่าน
                      </b>
                    </h5>
                    <span className="text-gray">คัดลอกรหัสผ่านอัตโนมัติ</span>
                  </button>
                ) : (
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      this.setState({ password: e.target.value })
                    }}
                    value={this.state.password}
                    readOnly
                  ></input>
                )}
              </div>
            </div>
            {/* ปุ่ม */}
            <div className="row">
              <div className="col-12 col-sm-12 col-md-9 col-xl-9 col-lg-9 ml-auto">
                <div className="row ">
                  <div className="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 mt-3">
                    <button
                      className="btn btn-secondary w-100"
                      onClick={() => {
                        this.clear_form()
                      }}
                    >
                      ยกเลิก
                    </button>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 mt-3">
                    <button
                      className="btn btn-primary w-100"
                      onClick={() => {
                        if (
                          this.state.fullname === "" ||
                          this.state.fullname === undefined
                        ) {
                          this.alert("info", "แจ้งเตือน", "กรุณากรอกชื่อ")
                          return
                        } else if (this.state.email.trim() === "") {
                          this.alert("info", "แจ้งเตือน", "กรุณากรอกอีเมล")
                          return
                        } else if (!this.validateEmail(this.state.email)) {
                          this.alert(
                            "info",
                            "แจ้งเตือน",
                            "รูปแบบอีเมลไม่ถูกต้อง"
                          )
                          return
                        } else if (this.state.phone.trim() === "") {
                          this.alert(
                            "info",
                            "แจ้งเตือน",
                            "กรุณากรอกเบอร์โทรศัพท์"
                          )
                          return
                        } else if (isNaN(this.state.phone)) {
                          this.alert(
                            "info",
                            "แจ้งเตือน",
                            "รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง"
                          )
                          return
                        } else if (this.state.phone.trim().length !== 10) {
                          this.alert(
                            "info",
                            "แจ้งเตือน",
                            "รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง"
                          )
                          return
                        } else if (this.state.project.trim() === "") {
                          this.alert("info", "แจ้งเตือน", "กรุณาเลือกโครงการ")
                          return
                        } else if (this.state.agency.trim() === "") {
                          this.alert("info", "แจ้งเตือน", "กรุณาเลือกหน่วยงาน")
                          return
                        } else if (this.state.role.trim() === "") {
                          this.alert("info", "แจ้งเตือน", "กรุณาเลือกบทบาท")
                          return
                        } else if (this.state.password.trim() === "") {
                          this.alert("info", "แจ้งเตือน", "กรุณากดสุ่มรหัสผ่าน")
                          return
                        }

                        // else if (this.state.verify_pin.trim().length !== 6) {
                        //   this.alert(
                        //     "info",
                        //     "แจ้งเตือน",
                        //     "กรุณากรอก PIN ให้ครบ 6 หลัก"
                        //   )
                        //   return
                        // } else if (this.state.verify_pin.trim() === "") {
                        //   this.alert("info", "แจ้งเตือน", "กรุณาตั้งรหัส PIN")
                        //   return
                        // }
                        this.Create()
                      }}
                    >
                      เพิ่มผู้ใช้งาน
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* modal edit */}
        <div className={this.state.modal_edit ? "show-modal" : "hide-modal"}>
          <div
            className="w-100 h-100 position-absolute"
            onClick={() => {
              this.clear_form()
            }}
          ></div>
          <div
            className={
              this.state.modal_edit ? "box-modal p-4" : "hide-box-modal p-4"
            }
          >
            <div className="d-flex justify-content-between">
              <h3>
                <b>แก้ไขผู้ใช้งาน</b>
              </h3>
              <button
                className="btn btn-link d-block d-md-none"
                onClick={() => {
                  this.clear_form()
                }}
              >
                <span className="icon mr-2 text-24">{"\uf00d"}</span>
              </button>
            </div>
            {/* ชื่อ-นามสกุล */}
            <div className="row mt-4">
              <div className="col-12 col-sm-4 col-md-4 col-xl-4 col-lg-4">
                <span>ชื่อ-นามสกุล:</span>
              </div>
              <div className="col-12 col-sm-8 col-md-8 col-xl-8 col-lg-8">
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ fullname: e.target.value })
                  }}
                  value={this.state.fullname}
                ></input>
              </div>
            </div>
            {/* อีเมล */}
            <div className="row mt-4">
              <div className="col-12 col-sm-4 col-md-4 col-xl-4 col-lg-4">
                <span>อีเมล:</span>
              </div>
              <div className="col-12 col-sm-8 col-md-8 col-xl-8 col-lg-8">
                <input
                  type="email"
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ email: e.target.value })
                  }}
                  value={this.state.email}
                ></input>
              </div>
            </div>
            {/* เบอร์โทร */}
            <div className="row mt-4">
              <div className="col-12 col-sm-4 col-md-4 col-xl-4 col-lg-4">
                <span>เบอร์โทร:</span>
              </div>
              <div className="col-12 col-sm-8 col-md-8 col-xl-8 col-lg-8">
                <input
                  type="tel"
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ phone: e.target.value })
                  }}
                  value={this.state.phone}
                  maxLength={10}
                ></input>
              </div>
            </div>
            <hr />
            {/* โครงการ */}
            <div className="row mt-3">
              <div className="col-12 col-sm-4 col-md-4 col-xl-4 col-lg-4">
                <span>โครงการ:</span>
              </div>
              <div className="col-12 col-sm-8 col-md-8 col-xl-8 col-lg-8">
                <select
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ project: e.target.value })
                    this.get_Agency(e.target.value)

                  }}
                  value={this.state.project}
                >
                  <option value="">-- เลือกโครงการ --</option>
                  {this.state.project_all_list.map((project, index) => (
                    <option value={project.project_id} key={index}>
                      {project.project_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {/* หน่วยงาน */}
            <div className="row mt-3">
              <div className="col-12 col-sm-4 col-md-4 col-xl-4 col-lg-4">
                <span>หน่วยงาน:</span>
              </div>
              <div className="col-12 col-sm-8 col-md-8 col-xl-8 col-lg-8">
                <select
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ agency: e.target.value })
                  }}
                  value={this.state.agency}
                >
                  <option value="">-- เลือกหน่วย --</option>
                  {this.state.agency_all_list.map((agency, index) => (
                    <option value={agency.agency_id} key={index}>
                      {agency.agency_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {/* 
            {this.state.agency_list &&
              this.state.agency_list.map((agency, index) => (
                <div className="row mt-3" key={index}>
                  <div className="col-12 col-sm-4 col-md-4 col-xl-4 col-lg-4"></div>
                  <div className="col-12 col-sm-8 col-md-8 col-xl-8 col-lg-8">
                    <select
                      className="form-control"
                      onChange={(e) => {
                        this.setState({ project: e.target.value });
                      }}
                      value={this.state.project}
                    >
                      <option value="">-- เลือกหน่วย --</option>
                    </select>
                  </div>
                </div>
              ))} */}
            <hr />
            {/* บทบาท */}
            <div className="row mt-3">
              <div className="col-12 col-sm-4 col-md-4 col-xl-4 col-lg-4">
                <span>บทบาท:</span>
              </div>
              <div className="col-12 col-sm-8 col-md-8 col-xl-8 col-lg-8">
                <select
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ role: e.target.value })
                  }}
                  value={this.state.role}
                >
                  <option value="">-- เลือกบทบาทผู้ดูแล --</option>
                  {this.state.role_list &&
                    this.state.role_list.map((role, index) => (
                      <option value={role.role_id}>{role.role_name}</option>
                    ))}
                </select>
              </div>
            </div>
            <hr />
            {/* ปุ่ม */}
            <div className="row">
              <div className="col-12 col-sm-12 col-md-9 col-xl-9 col-lg-9 ml-auto">
                <div className="row ">
                  <div className="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 mt-3">
                    <button
                      className="btn btn-secondary w-100"
                      onClick={() => {
                        this.clear_form()
                      }}
                    >
                      ยกเลิก
                    </button>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 mt-3">
                    <button
                      className="btn btn-primary w-100"
                      onClick={() => {
                        if (
                          this.state.fullname === "" ||
                          this.state.fullname === undefined
                        ) {
                          this.alert("info", "แจ้งเตือน", "กรุณากรอกชื่อ")
                          return
                        } else if (this.state.email.trim() === "") {
                          this.alert("info", "แจ้งเตือน", "กรุณากรอกอีเมล")
                          return
                        } else if (!this.validateEmail(this.state.email)) {
                          this.alert(
                            "info",
                            "แจ้งเตือน",
                            "รูปแบบอีเมลไม่ถูกต้อง"
                          )
                          return
                        } else if (this.state.phone.trim() === "") {
                          this.alert(
                            "info",
                            "แจ้งเตือน",
                            "กรุณากรอกเบอร์โทรศัพท์"
                          )
                          return
                        } else if (isNaN(this.state.phone)) {
                          this.alert(
                            "info",
                            "แจ้งเตือน",
                            "รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง"
                          )
                          return
                        } else if (this.state.phone.trim().length !== 10) {
                          this.alert(
                            "info",
                            "แจ้งเตือน",
                            "รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง"
                          )
                          return
                        } else if (this.state.project.trim() === "") {
                          this.alert("info", "แจ้งเตือน", "กรุณาเลือกโครงการ")
                          return
                        } else if (this.state.agency.trim() === "") {
                          this.alert("info", "แจ้งเตือน", "กรุณาเลือกหน่วยงาน")
                          return
                        } else if (this.state.role.trim() === "") {
                          this.alert("info", "แจ้งเตือน", "กรุณาเลือกบทบาท")
                          return
                        }

                        // else if (this.state.verify_pin.trim().length !== 6) {
                        //   this.alert(
                        //     "info",
                        //     "แจ้งเตือน",
                        //     "กรุณากรอก PIN ให้ครบ 6 หลัก"
                        //   )
                        //   return
                        // } else if (this.state.verify_pin.trim() === "") {
                        //   this.alert("info", "แจ้งเตือน", "กรุณาตั้งรหัส PIN")
                        //   return
                        // }
                        this.Edit()
                      }}
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* modal password */}
        <div
          className={this.state.modal_password ? "show-modal" : "hide-modal"}
        >
          <div
            className="w-100 h-100 position-absolute"
            onClick={() => {
              this.clear_form()
            }}
          ></div>
          <div
            className={
              this.state.modal_password ? "box-modal p-4" : "hide-box-modal p-4"
            }
          >
            <div className="d-flex justify-content-between">
              <h3>
                <b></b>
              </h3>
              <button
                className="btn btn-link d-block d-md-none"
                onClick={() => {
                  this.clear_form()
                }}
              >
                <span className="icon mr-2 text-24">{"\uf00d"}</span>
              </button>
            </div>
            {/* Username */}
            <div className="row mt-4">
              <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                <span>Username:</span>
              </div>
              <div className="col-12 col-sm-12 col-md-9 col-xl-9 col-lg-9">
                <input
                  type="text"
                  className="form-control"
                  readOnly
                  value={this.state.username}
                ></input>
              </div>
            </div>
            {/* รหัส PIN */}
            <div className="row mt-4">
              <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                <span>รหัส PIN:</span>
              </div>
              <div className="col-12 col-sm-12 col-md-9 col-xl-9 col-lg-9">
                <div class="input-group">
                  <input
                    type={!this.state.isVisible ? "password" : "text"}
                    className="form-control"
                    maxLength={6}
                    onChange={(e) => {
                      if (e.target.value.match(/^\d+$/)) {
                        this.setState({ verify_pin: e.target.value })
                      } else {
                        this.setState({ verify_pin: "" })
                      }
                    }}
                    value={this.state.verify_pin}
                    required
                  />
                  <div class="input-group-append">
                    <button
                      type="button"
                      class="btn btn-primary"
                      onClick={() => {
                        this.isVisiblePassword()
                      }}
                    >
                      <span
                        className="icon text-14"
                        onClick={() => {
                          this.isVisiblePassword()
                        }}
                      >
                        {this.state.isVisible ? "\uf06e" : "\uf070"}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* สุ่มรหัสผ่าน */}
            <div className="row mt-4">
              <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                <span>สุ่มรหัสผ่าน:</span>
              </div>
              <div className="col-12 col-sm-12 col-md-9 col-xl-9 col-lg-9">
                {this.state.password === "" ? (
                  <button
                    className="card w-100 p-3 align-items-center text-center bg-gray"
                    onClick={() => {
                      this.gen_Password()
                    }}
                  >
                    <h5>
                      <b className="text-primary">
                        คลิกที่นี่เพื่อสุ่มรหัสผ่าน
                      </b>
                    </h5>
                    <span className="text-gray">คัดลอกรหัสผ่านอัตโนมัติ</span>
                  </button>
                ) : (
                  <input
                    type="text"
                    className="form-control"
                    readOnly
                    onChange={(e) => {
                      this.setState({ password: e.target.value })
                    }}
                    value={this.state.password}
                  ></input>
                )}
              </div>
            </div>
            <hr />

            {/* ปุ่ม */}
            <div className="row">
              <div className="col-12 col-sm-12 col-md-9 col-xl-9 col-lg-9 ml-auto">
                <div className="row ">
                  <div className="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 mt-3">
                    <button
                      className="btn btn-secondary w-100"
                      onClick={() => {
                        this.clear_form()
                      }}
                    >
                      ยกเลิก
                    </button>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 mt-3">
                    <button
                      className="btn btn-primary w-100"
                      onClick={() => {
                        // if (this.state.verify_pin.trim().length !== 6) {
                        //   this.alert(
                        //     "info",
                        //     "แจ้งเตือน",
                        //     "กรุณากรอก PIN ให้ครบ 6 หลัก"
                        //   )
                        //   return
                        // } else if (this.state.verify_pin.trim() === "") {
                        //   this.alert("info", "แจ้งเตือน", "กรุณาตั้งรหัส PIN")
                        //   return
                        // }
                        setTimeout(() => {
                          this.EditPassword()
                        }, 10)
                      }}
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
