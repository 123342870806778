// JS
import "../../../global.js"

// COMPONENT
import HeaderComponent from "../../Header/Header"
import MenuComponent from "../../SideMenu/SideMenu"
import BreadcrumbComponent from "../../Breadcrumb/Breadcrumb"

// LIBRARY
import Swal from "sweetalert2"
import { toast } from "react-toastify"
import Cookies from "universal-cookie"
import React, { Component } from "react"
import Pagination from "react-js-pagination"

// CSS
import "./style.css"
import "../../../bootstrap.css"

const cookies = new Cookies()
toast.configure()

export default class AdminScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      modal_detail: false,
      modal_add: false,
      modal_add_project: false,
      modal_edit: false,
      modal_edit_project: false,
      modal_edit_password: false,

      token: cookies.get("token") ? "token " + cookies.get("token") : "",

      data: {},
      page: 1,

      user_id: "",
      fullname: "",
      email: "",
      phone: "",

      role: "",
      username: "",
      password: "",
      project:
        cookies.get("user_profile").agency_list &&
        cookies.get("user_profile").agency_list
          ? cookies.get("user_profile").agency_list
          : null,
      agency: "",
      data_search: "",

      detail: null,

      project_all_list: [],
      agency_all_list: [],
      role_list: [],
      agency_list: [],
      index: -1,
    }
  }

  componentDidMount() {
    this.get_All_Admin()
    this.get_Role_Website()
    this.get_Project()
  }

  alert(icon, title, detail) {
    Swal.fire({
      icon: icon,
      title: title,
      text: detail,
      confirmButtonText: "ตกลง",
    })
  }

  handlePageChange(pageNumber) {
    this.setState({ page: pageNumber })
    setTimeout(() => {
      this.get_All_Admin()
    }, 1)
  }
  get_All_Admin() {
    this.setState({ loading: true })
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
    }
    fetch(global.api + "v1/user/admin/page/" + this.state.page, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(
          "🚀 ~ file: Admin.js ~ line 96 ~ AdminScreen ~ .then ~ result",
          result
        )
        if (result.status) {
          this.setState({ data: result.data })
          this.setState({ loading: false })
        } else {
          switch (result.data.detail) {
            case "":
              break
            default:
              this.alert("info", "แจ้งเตือน", "เซสชันหมดอายุ")
              cookies.remove("token")
              window.location.href = "/"
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.setState({ loading: false })
      })
  }
  get_Role_Website() {
    this.setState({ loading: true })
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
    }
    fetch(global.api + "v1/user/role/website/all", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ role_list: result.data.data })
          this.setState({ loading: false })
        } else {
          switch (result.data.detail) {
            case "":
              break
            default:
              this.alert("info", "แจ้งเตือน", "เซสชันหมดอายุ")
              cookies.remove("token")
              window.location.href = "/"
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.setState({ loading: false })
      })
  }
  get_Project() {
    this.setState({ loading: true })
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    }
    fetch(global.api + "v1/project/all", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ project_all_list: result.data.data })
          this.setState({ loading: false })
        } else {
          switch (result.data.detail) {
            case "":
              break
            default:
              this.alert("info", "แจ้งเตือน", "เซสชันหมดอายุ")
              cookies.remove("token")
              window.location.href = "/"
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.setState({ loading: false })
      })
  }
  get_Agency(id, index) {
    this.setState({ loading: true })
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        project_id: id,
      }),
    }
    fetch(global.api + "v1/project/agency", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          let agency_all_list = this.state.agency_all_list
          agency_all_list[index] = result.data.data
          this.setState({ agency_all_list: agency_all_list })
          this.setState({ loading: false })
        } else {
          switch (result.data.detail) {
            case "":
              break
            default:
              this.alert("info", "แจ้งเตือน", "เซสชันหมดอายุ")
              cookies.remove("token")
              window.location.href = "/"
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.setState({ loading: false })
      })
  }
  getDeteil = async (admin, type) => {
    this.get_Agency(admin.project_id, 0)

    console.log("admin", admin)

    console.log("type", type)

    this.setState({
      user_id: admin.user_id,
      fullname: admin.full_name,
      email: admin.username,
      phone: admin.phone,
      agency_list: admin.agency_list,
      role: admin.role_data.role_id,
      project: admin.project_id,
      agency: admin.agency_id,
      detail: admin,
    })

    let agency_all_list = this.state.agency_all_list
    for (let agency of admin.agency_list) {
      agency_all_list.push([agency])
    }
    this.setState({ agency_all_list: agency_all_list })

    if (type === 1) {
      this.setState({
        modal_detail: true,
      })
    } else if (type === 2) {
      this.setState({
        modal_edit: true,
      })
    } else if (type === 3) {
      let index = 0
      for (let ag of admin.agency_list) {
        this.get_Agency(ag.project_id, index + 1)
        index += 1
      }

      this.setState({
        modal_edit_project: true,
      })
    }
  }
  getDetailPassword = (admin) => {
    this.setState({
      modal_edit_password: true,
      user_id: admin.user_id,
      username: admin.username,
    })
  }
  gen_Password = () => {
    let result = ""
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
    var charactersLength = characters.length
    for (var i = 0; i < 6; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    this.setState({ password: result })
  }
  Create = () => {
    this.setState({ loading: true })

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
      body: JSON.stringify({
        email: this.state.email,
        password: this.state.password,
        first_name: this.state.fullname,
        phone: this.state.phone,
        agency_id: this.state.agency,
        agency_list: this.state.agency_list,
        role_id: this.state.role,
        picture: "",
      }),
    }
    fetch(global.api + "v1/user/create", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.clear_form()

          this.alert("success", "สำเร็จ", "เพิ่มผู้ดูแลเว็บไซต์แล้ว")
          this.setState({ page: 1 })

          window.location.href = "/admin"
        } else {
          switch (result.data.message) {
            case "first name is none":
              this.alert("info", "แจ้งเตือน", "กรุณากรอกชื่อ")
              break
            case "email is none":
              this.alert("info", "แจ้งเตือน", "กรุณากรอกอีเมล")
              break
            case "phone is none":
              this.alert("info", "แจ้งเตือน", "กรุณากรอกเบอร์โทรศัพท์")
              break
            case "password is none":
              this.alert("info", "แจ้งเตือน", "กรุณากดสุ่มรหัสผ่าน")
              break
            case "role id not found":
              this.alert("info", "แจ้งเตือน", "กรุณาเลือกบทบาท")
              break
            case "agency id not found":
              this.alert("info", "แจ้งเตือน", "กรุณาเลือกหน่วยงาน")
              break
            case "email is duplicate":
              this.alert("info", "แจ้งเตือน", "อีเมลนี้ถูกใช้งานไปแล้ว")
              break
            case "Agency is not found":
              this.alert("info", "แจ้งเตือน", "กรุณาเลือกโครงการที่จัดการได้")
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.setState({ loading: false })
      })
  }
  Edit = () => {
    this.setState({ loading: true })

    let agency = []
    for (let ag of this.state.agency_list) {
      if (ag.agency_id) {
        agency.push(ag.agency_id)
      } else {
        agency.push(ag)
      }
    }

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
      body: JSON.stringify({
        user_id: this.state.user_id,
        email: this.state.email,
        password: this.state.password,
        first_name: this.state.fullname,
        phone: this.state.phone,
        agency_id: this.state.agency,
        agency_list: agency,
        role_id: this.state.role,
        picture: "",
      }),
    }
    fetch(global.api + "v1/user/update", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.clear_form()
          this.alert("success", "สำเร็จ", "แก้ไขผู้ดูแลเว็บไซต์แล้ว")
          this.setState({ page: 1 })
          window.location.href = "/admin"
        } else {
          switch (result.data.message) {
            case "first name is none":
              this.alert("info", "แจ้งเตือน", "กรุณากรอกชื่อ")
              break
            case "last name is none":
              this.alert("info", "แจ้งเตือน", "กรุณากรอกนามสกุล")
              break
            case "email is none":
              this.alert("info", "แจ้งเตือน", "กรุณากรอกอีเมล")
              break
            case "phone is none":
              this.alert("info", "แจ้งเตือน", "กรุณากรอกเบอร์โทรศัพท์")
              break
            case "password is none":
              this.alert("info", "แจ้งเตือน", "กรุณากดสุ่มรหัสผ่าน")
              break
            case "role id not found":
              this.alert("info", "แจ้งเตือน", "กรุณาเลือกบทบาท")
              break
            case "Agency is not found":
              this.alert("info", "แจ้งเตือน", "กรุณาเลือกหน่วยงาน")
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.setState({ loading: false })
      })
  }
  EditPassword = () => {
    this.setState({ loading: true })
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
      body: JSON.stringify({
        user_id: this.state.user_id,
        password: this.state.password,
        username: this.state.username,
      }),
    }
    fetch(global.api + "v1/user/update-password", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.clear_form()
          this.alert("success", "สำเร็จ", "เปลี่ยนรหัสผ่านแล้ว")
          this.setState({ page: 1 })
          window.location.href = "/admin"
        } else {
          switch (result.data.message) {
            case "password is none":
              this.alert("info", "แจ้งเตือน", "กรุณากดสุ่มรหัสผ่าน")
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.setState({ loading: false })
      })
  }
  Delete = (admin) => {
    Swal.fire({
      icon: "info",
      title: "ลบผู้ดูแลเว็บไซต์",
      text: "ท่านแน่ใจว่าต้องการลบผู้ดูแลเว็บไซต์ “" + admin.full_name + "”",
      showCancelButton: true,
      confirmButtonText: "ลบข้อมูล",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.isConfirmed) {
        const requestOptions = {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
            Authorization: this.state.token,
          },
          accept: "application/json",
        }
        fetch(global.api + "v1/user/delete/" + admin.user_id, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            if (result.status) {
              this.setState({ loading: false, page: 1 })
              this.alert("success", "แจ้งเตือน", "ลบผู้ดูแลเว็บไซต์สำเร็จ")
              this.get_All_Admin()
            } else {
              switch (result.data.detail) {
                case "":
                  break
                default:
                  this.alert("info", "แจ้งเตือน", "เซสชันหมดอายุ")
                  cookies.remove("token")
                  window.location.href = "/"
                  break
              }
              this.setState({ loading: false })
            }
          })
          .catch((e) => {
            this.setState({ loading: false })
          })
      }
    })
  }
  validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  }
  search() {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
      body: JSON.stringify({
        user_type: "admin",
        data_search: this.state.data_search,
      }),
    }
    fetch(
      global.api + "v1/user/search-user/page/" + this.state.page,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ data: result.data })
          this.setState({ loading: false })
        } else {
          switch (result.data.message) {
            case "This Page is not available":
              this.alert("info", "แจ้งเตือน", "ไม่พบหน้า")
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.setState({ loading: false })
      })
  }
  clear_form() {
    console.log("clear_form")
    this.setState({
      modal_detail: false,
      modal_add: false,
      modal_add_project: false,
      modal_edit: false,
      modal_edit_project: false,
      modal_edit_password: false,
      user_id: "",
      fullname: "",
      email: "",
      phone: "",
      agency_list: [],
      role: "",
      username: "",
      password: "",
      // project: "",
      agency: "",
      detail: null,
      // project_all_list: [],
      agency_all_list: [],
      // role_list: [],
    })
  }
  clear_form_edit() {
    this.setState({
      modal_detail: false,
      modal_add: false,
      modal_add_project: false,
      modal_edit: false,
      modal_edit_project: false,
      modal_edit_password: false,
      user_id: "",
      fullname: "",
      email: "",
      phone: "",
      agency_list: [],
      role: "",
      username: "",
      password: "",
      project: "",
      agency: "",
      detail: null,
    })
  }

  render() {
    return (
      <div className="full bg-contain">
        <div className="menu-container">
          <MenuComponent />
          <div className="w-100">
            <BreadcrumbComponent
              page1="ผู้ดูแลเว็บไซต์"
              page2="ผู้ดูแลเว็บไซต์"
            />
            <HeaderComponent header="ผู้ดูแลเว็บไซต์" report="false" />
            {/* เมนู ฟิลเตอร์  */}
            <div className="bg-gray px-3 pt-3 w-100">
              <div className="row d-flex mx-0 justify-content-between">
                <div className="d-flex row mx-0">
                  {/* ค้นหา */}
                  <div className="mx-2 mb-3">
                    <input
                      className="form-control bg-white"
                      type="search"
                      placeholder="ค้นหา..."
                      value={this.state.data_search}
                      onChange={(e) => {
                        this.setState({ data_search: e.target.value })

                        if (e.target.value === "") {
                          this.setState({ page: 1 })
                          this.get_All_Admin()
                        }
                      }}
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          this.setState({
                            page: 1,
                          })

                          setTimeout(() => {
                            this.search()
                          }, 5)
                        }
                      }}
                    ></input>
                  </div>
                </div>
                <div className="d-flex row mx-0">
                  <div className="mx-2 mb-3">
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        this.setState({ modal_add: true })
                        this.get_Project()
                        this.get_Role_Website()
                      }}
                    >
                      + เพิ่มผู้ดูแลเว็บไซต์
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* ตาราง */}

            {this.state.data &&
            this.state.data.data &&
            this.state.data.data.length === 0 ? (
              <div className="w-100 p-3">
                <div
                  className="table-responsive"
                  style={{ minHeight: "100vh" }}
                >
                  {this.state.index !== -1 ? (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        left: 0,
                        top: 0,
                        zIndex: 1,
                      }}
                      onClick={() => {
                        this.setState({ index: -1 })
                      }}
                    ></div>
                  ) : null}
                  <table className="table design-table">
                    <thead>
                      <tr>
                        <th className="text-left">ชื่อ-นามสกุล</th>
                        <th className="text-left">อีเมล</th>
                        <th className="text-left">หมายเลขโทรศัพท์</th>
                        <th className="text-left">บทบาท</th>
                        <th className="text-center  w-150px">จัดการ</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan={5} className="text-center">
                          ไม่มีข้อมูล
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div className="w-100 p-3">
                <div
                  className="table-responsive"
                  style={{ minHeight: "100vh" }}
                >
                  {this.state.index !== -1 ? (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        left: 0,
                        top: 0,
                        zIndex: 1,
                      }}
                      onClick={() => {
                        this.setState({ index: -1 })
                      }}
                    ></div>
                  ) : null}
                  <table className="table design-table">
                    <thead>
                      <tr>
                        <th className="text-left">ชื่อ-นามสกุล</th>
                        <th className="text-left">อีเมล</th>
                        <th className="text-left">หมายเลขโทรศัพท์</th>
                        <th className="text-left">บทบาท</th>
                        <th className="text-center  w-150px">จัดการ</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data &&
                        this.state.data.data &&
                        this.state.data.data.map((admin, index) => (
                          <tr key={index}>
                            <td>{admin.full_name}</td>
                            <td>{admin.username}</td>
                            <td>{admin.phone}</td>
                            <td>{admin.role_data.role_name}</td>

                            <td className="text-center w-150px">
                              <div className="d-flex justify-content-center">
                                <div className="dropdown">
                                  <button
                                    type="button"
                                    className="dropbtns btn btn-link"
                                    onClick={() => {
                                      if (this.state.index === index) {
                                        this.setState({ index: -1 })
                                      } else {
                                        this.setState({ index: index })
                                      }
                                    }}
                                  >
                                    <span className="icon-brand text-24">
                                      {"\uf141"}
                                    </span>
                                  </button>
                                  <div
                                    className={
                                      this.state.index === index
                                        ? "dropdowns-content bg-white rounded text-left show"
                                        : "dropdowns-content bg-white rounded text-left"
                                    }
                                  >
                                    <a
                                      href="#"
                                      onClick={() => {
                                        this.getDeteil(admin, 1)
                                        this.setState({ index: -1 })
                                      }}
                                    >
                                      <span className="icon mr-2">
                                        {"\uf06e"}
                                      </span>{" "}
                                      รายละเอียด
                                    </a>
                                    <a
                                      href="#"
                                      onClick={() => {
                                        this.getDeteil(admin, 2)
                                        this.setState({ index: -1 })
                                      }}
                                    >
                                      <span className="icon mr-2">
                                        {"\uf304"}
                                      </span>{" "}
                                      แก้ไขข้อมูล
                                    </a>
                                    <a
                                      href="#"
                                      onClick={() => {
                                        this.getDeteil(admin, 3)
                                        this.setState({ index: -1 })
                                      }}
                                    >
                                      <span className="icon mr-2">
                                        {"\uf828"}
                                      </span>{" "}
                                      โครงการที่จัดการได้
                                    </a>
                                    <a
                                      href="#"
                                      onClick={() => {
                                        this.getDetailPassword(admin)
                                        this.setState({ index: -1 })
                                      }}
                                    >
                                      <span className="icon mr-2">
                                        {"\uf084"}
                                      </span>{" "}
                                      เปลี่ยนรหัสผ่าน
                                    </a>
                                    <a
                                      href="#"
                                      onClick={() => {
                                        this.Delete(admin)
                                        this.setState({ index: -1 })
                                      }}
                                    >
                                      <span className="icon mr-2 text-danger">
                                        {"\uf1f8"}
                                      </span>{" "}
                                      ลบข้อมูล
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan={5} class="px-0 mx-0">
                          {/* pagination */}
                          {this.state.data && (
                            <div className="d-flex justify-content-between row mx-0 w-100">
                              <div>
                                <span>
                                  แสดง {this.state.data.end_index} จาก{" "}
                                  {this.state.data.count} รายการ
                                </span>
                              </div>
                              <div className="d-flex">
                                <button
                                  className="btn-circle-white mx-1"
                                  onClick={() => {
                                    this.handlePageChange(1)
                                  }}
                                >
                                  <span className="icon">{"\uf100"}</span>
                                </button>
                                <Pagination
                                  activePage={this.state.page}
                                  itemsCountPerPage={10}
                                  totalItemsCount={this.state.data.count}
                                  pageRangeDisplayed={5}
                                  onChange={this.handlePageChange.bind(this)}
                                  prevPageText={
                                    <span className="icon">{"\uf104"}</span>
                                  }
                                  nextPageText={
                                    <span className="icon">{"\uf105"}</span>
                                  }
                                  hideFirstLastPages={true}
                                />
                                <button
                                  className="btn-circle-white mx-1"
                                  onClick={() => {
                                    this.handlePageChange(
                                      this.state.data.page_number
                                    )
                                  }}
                                >
                                  <span className="icon">{"\uf101"}</span>
                                </button>
                              </div>
                            </div>
                          )}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* modal detail */}
        <div className={this.state.modal_detail ? "show-modal" : "hide-modal"}>
          <div
            className="w-100 h-100 position-absolute"
            onClick={() => {
              this.clear_form_edit()
            }}
          ></div>
          <div
            className={
              this.state.modal_detail ? "box-modal p-4" : "hide-box-modal p-4"
            }
          >
            <div className="d-flex justify-content-between">
              <h3>
                <b>รายละเอียด</b>
              </h3>
              <button
                className="btn btn-link d-block d-md-none"
                onClick={() => {
                  this.clear_form_edit()
                }}
              >
                <span className="icon mr-2 text-24">{"\uf00d"}</span>
              </button>
            </div>
            {/* ชื่อ-นามสกุล */}
            <div className="row mt-4 d-flex mx-0 justify-content-between">
              <span class="text-secondary">ชื่อ-นามสกุล:</span>
              <span className="w-75 text-right">
                {this.state.detail ? this.state.detail.first_name : ""}
              </span>
            </div>
            {/* อีเมล */}
            <div className="row mt-4 d-flex mx-0 justify-content-between">
              <span class="text-secondary">อีเมล:</span>
              <span className="w-75 text-right">
                {this.state.detail ? this.state.detail.username : ""}
              </span>
            </div>
            {/* เบอร์โทร */}
            <div className="row mt-4 d-flex mx-0 justify-content-between">
              <span class="text-secondary">เบอร์โทร:</span>
              <span className="w-75 text-right">
                {this.state.detail ? this.state.detail.phone : ""}
              </span>
            </div>
            <hr />
            {/* โครงการ */}
            <div className="row mt-4 d-flex mx-0 justify-content-between">
              <span class="text-secondary">โครงการ:</span>
              <span className="w-75 text-right">
                {this.state.detail ? this.state.detail.project_name : ""}
              </span>
            </div>
            {/* หน่วยงาน */}
            <div className="row mt-4 d-flex mx-0 justify-content-between">
              <span class="text-secondary">หน่วยงาน:</span>
              <span className="w-75 text-right">
                {this.state.detail ? this.state.detail.agency_name : ""}
              </span>
            </div>
            <hr />
            {/* แก้ไขข้อมูล */}
            <div className="row mt-2">
              <div className="col-12 text-center">
                <button
                  class="btn btn-link text-primary"
                  onClick={() => {
                    this.setState({ modal_detail: false })
                    this.getDeteil(this.state.detail, 2)
                  }}
                >
                  <span className="icon mr-2">{"\uf304"}</span>แก้ไขข้อมูล
                </button>
              </div>
            </div>
            <div className="d-flex justify-content-between mt-4">
              <h3>
                <b>โครงการที่จัดการได้</b>
              </h3>
            </div>
            {this.state.detail &&
              this.state.detail.agency_list.map((item, index) => (
                <>
                  {/* โครงการ */}
                  <div className="row mt-4 d-flex mx-0 justify-content-between">
                    <span class="text-secondary">โครงการ:</span>
                    <span className="w-75 text-right">{item.project_name}</span>
                  </div>
                  {/* หน่วยงาน */}
                  <div className="row mt-4 d-flex mx-0 justify-content-between">
                    <span class="text-secondary">หน่วยงาน:</span>
                    <span className="w-75 text-right">{item.agency_name}</span>
                  </div>
                  <hr />
                </>
              ))}

            {/* แก้ไขข้อมูล */}
            <div className="row mt-2">
              <div className="col-12 text-center ">
                <button
                  class="btn btn-link text-primary"
                  onClick={() => {
                    this.setState({ modal_detail: false })
                    this.getDeteil(this.state.detail, 3)
                  }}
                >
                  <span className="icon mr-2">{"\uf304"}</span>
                  แก้ไขโครงการที่จัดการได้
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* modal add user */}
        <div className={this.state.modal_add ? "show-modal" : "hide-modal"}>
          <div
            className="w-100 h-100 position-absolute"
            onClick={() => {
              this.clear_form()
            }}
          ></div>
          <div
            className={
              this.state.modal_add ? "box-modal p-4" : "hide-box-modal p-4"
            }
          >
            <div className="d-flex justify-content-between">
              <h3>
                <b>เพิ่มผู้ดูแลเว็บไซต์</b>
              </h3>
              <button
                className="btn btn-link d-block d-md-none"
                onClick={() => {
                  this.clear_form()
                }}
              >
                <span className="icon mr-2 text-24">{"\uf00d"}</span>
              </button>
            </div>

            {/* ชื่อ-นามสกุล */}
            <div className="row mt-4">
              <div className="col-12 col-sm-3 col-md-3 col-xl-3 col-lg-3">
                <span>ชื่อ-นามสกุล:</span>
              </div>
              <div className="col-12 col-sm-9 col-md-9 col-xl-9 col-lg-9">
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ fullname: e.target.value })
                  }}
                  value={this.state.fullname}
                ></input>
              </div>
            </div>

            {/* อีเมล */}
            <div className="row mt-4">
              <div className="col-12 col-sm-3 col-md-3 col-xl-3 col-lg-3">
                <span>อีเมล:</span>
              </div>
              <div className="col-12 col-sm-9 col-md-9 col-xl-9 col-lg-9">
                <input
                  type="email"
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ email: e.target.value })
                  }}
                  value={this.state.email}
                ></input>
              </div>
            </div>
            {/* เบอร์โทร */}
            <div className="row mt-4">
              <div className="col-12 col-sm-3 col-md-3 col-xl-3 col-lg-3">
                <label>เบอร์โทร:</label>
              </div>
              <div className="col-12 col-sm-9 col-md-9 col-xl-9 col-lg-9">
                <input
                  type="tel"
                  className="form-control"
                  maxLength={10}
                  onChange={(e) => {
                    this.setState({ phone: e.target.value })
                  }}
                  value={this.state.phone}
                ></input>
              </div>
            </div>
            <hr />
            {/* โครงการ */}
            <div className="row mt-3">
              <div className="col-12 col-sm-3 col-md-3 col-xl-3 col-lg-3">
                <label>โครงการ:</label>
              </div>
              <div className="col-12 col-sm-9 col-md-9 col-xl-9 col-lg-9">
                <select
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ project: e.target.value })
                    if (e.target.value === "") {
                      let agency_all_list = this.state.agency_all_list
                      agency_all_list[0][0] = ""
                      this.setState({ agency_all_list: agency_all_list })
                    } else {
                      this.get_Agency(e.target.value, 0)
                    }
                  }}
                >
                  <option value="">-- เลือกโครงการ --</option>
                  {this.state.project_all_list.map((project, index) => (
                    <option key={index} value={project.project_id}>
                      {project.project_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {/* หน่วยงาน */}
            <div className="row mt-3">
              <div className="col-12 col-sm-3 col-md-3 col-xl-3 col-lg-3">
                <label>หน่วยงาน:</label>
              </div>
              <div className="col-12 col-sm-9 col-md-9 col-xl-9 col-lg-9">
                <select
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ agency: e.target.value })
                  }}
                  value={this.state.agency}
                >
                  <option value="">-- เลือกหน่วย --</option>
                  {this.state.agency_all_list &&
                    this.state.agency_all_list[0] &&
                    this.state.agency_all_list[0].map((agency, index) => (
                      <option value={agency.agency_id}>
                        {agency.agency_name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <hr />
            {/* บทบาท */}
            <div className="row mt-3">
              <div className="col-12 col-sm-3 col-md-3 col-xl-3 col-lg-3">
                <label>บทบาท:</label>
              </div>
              <div className="col-12 col-sm-9 col-md-9 col-xl-9 col-lg-9">
                <select
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ role: e.target.value })
                  }}
                  value={this.state.role}
                >
                  <option value="">-- เลือกบทบาทผู้ดูแล --</option>
                  {this.state.role_list &&
                    this.state.role_list.map((role, index) => (
                      <option value={role.role_id}>{role.role_name}</option>
                    ))}
                </select>
              </div>
            </div>
            <hr />
            {/* สุ่มรหัสผ่าน */}
            <div className="row mt-4">
              <div className="col-12 col-sm-4 col-md-4 col-xl-4 col-lg-4">
                <span>สุ่มรหัสผ่าน:</span>
              </div>
              <div className="col-12 col-sm-8 col-md-8 col-xl-8 col-lg-8">
                {this.state.password === "" ? (
                  <button
                    className="card w-100 p-3 align-items-center text-center bg-gray"
                    onClick={() => {
                      this.gen_Password()
                    }}
                  >
                    <h5>
                      <b className="text-primary">
                        คลิกที่นี่เพื่อสุ่มรหัสผ่าน
                      </b>
                    </h5>
                    <span className="text-gray">คัดลอกรหัสผ่านอัตโนมัติ</span>
                  </button>
                ) : (
                  <input
                    type="text"
                    className="form-control"
                    readOnly
                    onChange={(e) => {
                      this.setState({ password: e.target.value })
                    }}
                    value={this.state.password}
                  ></input>
                )}
              </div>
            </div>
            {/* ปุ่ม */}
            <div className="row">
              <div className="col-12 col-sm-9 col-md-9 col-xl-9 col-lg-9 ml-auto">
                <div className="row ">
                  <div className="col-12 col-sm-6 col-md-6 col-xl-6 col-lg-6 mt-3">
                    <button
                      className="btn btn-secondary w-100"
                      onClick={() => {
                        this.clear_form()
                      }}
                    >
                      ยกเลิก
                    </button>
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-xl-6 col-lg-6 mt-3">
                    <button
                      className="btn btn-primary w-100"
                      onClick={() => {
                        var str_phone = /^[0-9]/
                        if (
                          this.state.fullname === "" ||
                          this.state.fullname === undefined
                        ) {
                          this.alert(
                            "info",
                            "แจ้งเตือน",
                            "กรุณากรอกชื่อ-นามสกุล"
                          )
                          return
                        } else if (this.state.email.trim() === "") {
                          this.alert("info", "แจ้งเตือน", "กรุณากรอกอีเมล")
                          return
                        } else if (!this.validateEmail(this.state.email)) {
                          this.alert(
                            "info",
                            "แจ้งเตือน",
                            "รูปแบบอีเมลไม่ถูกต้อง"
                          )
                          return
                        } else if (this.state.phone.trim() === "") {
                          this.alert(
                            "info",
                            "แจ้งเตือน",
                            "กรุณากรอกเบอร์โทรศัพท์"
                          )
                          return
                        } else if (isNaN(this.state.phone)) {
                          this.alert(
                            "info",
                            "แจ้งเตือน",
                            "รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง"
                          )
                          return
                        } else if (this.state.phone.trim().length != 10) {
                          this.alert(
                            "info",
                            "แจ้งเตือน",
                            "กรุณากรอกเบอร์โทรศัพท์ให้ครบ 10 หลัก"
                          )
                          return
                        } else if (this.state.project.trim() === "") {
                          this.alert("info", "แจ้งเตือน", "กรุณาเลือกโครงการ")
                          return
                        } else if (this.state.agency.trim() === "") {
                          this.alert("info", "แจ้งเตือน", "กรุณาเลือกหน่วยงาน")
                          return
                        } else if (this.state.role.trim() === "") {
                          this.alert("info", "แจ้งเตือน", "กรุณาเลือกบทบาท")
                          return
                        } else if (this.state.password.trim() === "") {
                          this.alert("info", "แจ้งเตือน", "กรุณากดสุ่มรหัสผ่าน")
                          return
                        }

                        this.setState({
                          modal_add: false,
                          modal_add_project: true,
                        })
                      }}
                    >
                      ถัดไป
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* modal add project */}
        <div
          className={this.state.modal_add_project ? "show-modal" : "hide-modal"}
        >
          <div
            className="w-100 h-100 position-absolute"
            onClick={() => {
              this.clear_form_edit()
            }}
          ></div>
          <div
            className={
              this.state.modal_add_project
                ? "box-modal p-4"
                : "hide-box-modal p-4"
            }
          >
            <button
              class="btn btn-link mb-3"
              onClick={() => {
                this.setState({
                  modal_add: true,
                  modal_add_project: false,
                })
              }}
            >
              <span className="icon mr-2 text-18">{"\uf060"}</span>
              ย้อนกลับ
            </button>
            <div className="d-flex justify-content-between">
              <h3>
                <b>โครงการที่จัดการได้</b>
              </h3>
              <button
                className="btn btn-link d-block d-md-none"
                onClick={() => {
                  this.clear_form_edit()
                }}
              >
                <span className="icon mr-2 text-24">{"\uf00d"}</span>
              </button>
            </div>
            {this.state.agency_list &&
              this.state.agency_list.map((agency, index) => (
                <>
                  <hr />
                  {/* โครงการ */}
                  <div className="row mt-3">
                    <div className="col-12 col-sm-3 col-md-3 col-xl-3 col-lg-3">
                      <span>โครงการ:</span>
                    </div>
                    <div className="col-12 col-sm-9 col-md-9 col-xl-9 col-lg-9">
                      <select
                        className="form-control"
                        onChange={(e) => {
                          this.setState({ project: e.target.value })
                          this.get_Agency(e.target.value, index + 1)
                        }}
                      >
                        <option value="">-- เลือกโครงการ --</option>
                        {this.state.project_all_list.map((project, index) => (
                          <option value={project.project_id}>
                            {project.project_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {/* หน่วยงาน */}
                  <div className="row mt-3">
                    <div className="col-12 col-sm-3 col-md-3 col-xl-3 col-lg-3">
                      <span>หน่วยงาน:</span>
                    </div>
                    <div className="col-12 col-sm-9 col-md-9 col-xl-9 col-lg-9">
                      <select
                        className="form-control"
                        onChange={(e) => {
                          let agency_list = this.state.agency_list
                          agency_list[index] = e.target.value
                          this.setState({ agency_list: agency_list })
                        }}
                        value={agency}
                      >
                        <option value="">-- เลือกหน่วย --</option>
                        {this.state.agency_all_list &&
                          this.state.agency_all_list[index + 1] &&
                          this.state.agency_all_list[index + 1].map(
                            (agency_all, index) => (
                              <option value={agency_all.agency_id}>
                                {agency_all.agency_name}
                              </option>
                            )
                          )}
                      </select>
                    </div>
                  </div>
                  <div className="w-100 text-right mt-3">
                    <button
                      className="btn btn-danger"
                      onClick={() => {
                        let agency_list = this.state.agency_list
                        agency_list.splice(index, 1)
                        this.setState({ agency_list: agency_list })
                        let agency_all_list = this.state.agency_all_list
                        agency_all_list.splice(index + 1, 1)
                        this.setState({ agency_all_list: agency_all_list })
                      }}
                    >
                      <span className="icon mr-2 text-18">{"\uf1f8"}</span>
                      ลบโครงการ
                    </button>
                  </div>
                </>
              ))}

            <hr />
            {/* เพิ่มโครงการ */}
            <div className="w-100 text-right mt-3">
              <button
                className="btn btn-outline-primary"
                onClick={() => {
                  let agency_list = this.state.agency_list
                  agency_list.push("")
                  this.setState({ agency_list: agency_list })
                }}
              >
                + เพิ่มโครงการ
              </button>
            </div>

            <hr />
            {/* ปุ่ม */}
            <div className="row">
              <div className="col-12 col-sm-9 col-md-9 col-xl-9 col-lg-9 ml-auto">
                <div className="row ">
                  <div className="col-12 col-sm-6 col-md-6 col-xl-6 col-lg-6 mt-3">
                    <button
                      className="btn btn-secondary w-100"
                      onClick={() => {
                        this.clear_form_edit()
                      }}
                    >
                      ยกเลิก
                    </button>
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-xl-6 col-lg-6 mt-3">
                    <button
                      className="btn btn-primary w-100"
                      onClick={() => {
                        this.Create()
                      }}
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* modal edit */}
        <div className={this.state.modal_edit ? "show-modal" : "hide-modal"}>
          <div
            className="w-100 h-100 position-absolute"
            onClick={() => {
              this.clear_form_edit()
            }}
          ></div>
          <div
            className={
              this.state.modal_edit ? "box-modal p-4" : "hide-box-modal p-4"
            }
          >
            <div className="d-flex justify-content-between">
              <h3>
                <b>แก้ไขผู้ดูแลเว็บไซต์</b>
              </h3>
              <button
                className="btn btn-link d-block d-md-none"
                onClick={() => {
                  this.clear_form_edit()
                }}
              >
                <span className="icon mr-2 text-24">{"\uf00d"}</span>
              </button>
            </div>
            {/* ชื่อ-นามสกุล */}
            <div className="row mt-4">
              <div className="col-12 col-sm-4 col-md-4 col-xl-4 col-lg-4">
                <span>ชื่อ-นามสกุล:</span>
              </div>
              <div className="col-12 col-sm-8 col-md-8 col-xl-8 col-lg-8">
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ fullname: e.target.value })
                  }}
                  value={this.state.fullname}
                ></input>
              </div>
            </div>
            {/* อีเมล */}
            <div className="row mt-4">
              <div className="col-12 col-sm-4 col-md-4 col-xl-4 col-lg-4">
                <span>อีเมล:</span>
              </div>
              <div className="col-12 col-sm-8 col-md-8 col-xl-8 col-lg-8">
                <input
                  type="email"
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ email: e.target.value })
                  }}
                  disabled
                  value={this.state.email}
                ></input>
              </div>
            </div>
            {/* เบอร์โทร */}
            <div className="row mt-4">
              <div className="col-12 col-sm-4 col-md-4 col-xl-4 col-lg-4">
                <span>เบอร์โทร:</span>
              </div>
              <div className="col-12 col-sm-8 col-md-8 col-xl-8 col-lg-8">
                <input
                  type="tel"
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ phone: e.target.value })
                  }}
                  value={this.state.phone}
                  maxLength={10}
                ></input>
              </div>
            </div>
            <hr />
            {/* โครงการ */}
            <div className="row mt-3">
              <div className="col-12 col-sm-4 col-md-4 col-xl-4 col-lg-4">
                <span>โครงการ:</span>
              </div>
              <div className="col-12 col-sm-8 col-md-8 col-xl-8 col-lg-8">
                <select
                  className="form-control"
                  onChange={(e) => {
                    if (e.target.value === "") {
                      let agency_all_list = this.state.agency_all_list
                      agency_all_list[0][0] = ""
                      this.setState({ agency_all_list: agency_all_list })
                    } else {
                      this.setState({ project: e.target.value })

                      this.get_Agency(e.target.value, 0)
                    }
                  }}
                  value={this.state.project}
                >
                  <option value="">-- เลือกโครงการ --</option>
                  {this.state.project_all_list.map((project, index) => (
                    <option key={index} value={project.project_id}>
                      {project.project_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {/* หน่วยงาน */}
            <div className="row mt-3">
              <div className="col-12 col-sm-4 col-md-4 col-xl-4 col-lg-4">
                <span>หน่วยงาน:</span>
              </div>
              <div className="col-12 col-sm-8 col-md-8 col-xl-8 col-lg-8">
                <select
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ agency: e.target.value })
                  }}
                  value={this.state.agency}
                >
                  <option value="">-- เลือกหน่วย --</option>
                  {this.state.agency_all_list &&
                    this.state.agency_all_list[0] &&
                    this.state.agency_all_list[0].map((agency, index) => (
                      <option value={agency.agency_id} key={index}>
                        {agency.agency_name}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            <hr />
            {/* บทบาท */}
            <div className="row mt-3">
              <div className="col-12 col-sm-4 col-md-4 col-xl-4 col-lg-4">
                <span>บทบาท:</span>
              </div>
              <div className="col-12 col-sm-8 col-md-8 col-xl-8 col-lg-8">
                <select
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ role: e.target.value })
                  }}
                  value={this.state.role}
                >
                  <option value="">-- เลือกบทบาทผู้ดูแล --</option>
                  {this.state.role_list &&
                    this.state.role_list.map((role, index) => (
                      <option key={index} value={role.role_id}>
                        {role.role_name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <hr />
            {/* ปุ่ม */}
            <div className="row">
              <div className="col-12 col-sm-9 col-md-9 col-xl-9 col-lg-9 ml-auto">
                <div className="row ">
                  <div className="col-12 col-sm-6 col-md-6 col-xl-6 col-lg-6 mt-3">
                    <button
                      className="btn btn-secondary w-100"
                      onClick={() => {
                        this.clear_form_edit()
                      }}
                    >
                      ยกเลิก
                    </button>
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-xl-6 col-lg-6 mt-3">
                    <button
                      className="btn btn-primary w-100"
                      onClick={() => {
                        var str_phone = /^[0-9]/

                        if (
                          this.state.fullname === "" ||
                          this.state.fullname === undefined
                        ) {
                          this.alert("info", "แจ้งเตือน", "กรุณากรอกชื่อ")
                          return
                        } else if (this.state.email.trim() === "") {
                          this.alert("info", "แจ้งเตือน", "กรุณากรอกอีเมล")
                          return
                        } else if (!this.validateEmail(this.state.email)) {
                          this.alert(
                            "info",
                            "แจ้งเตือน",
                            "รูปแบบอีเมลไม่ถูกต้อง"
                          )
                          return
                        } else if (this.state.phone.trim() === "") {
                          this.alert(
                            "info",
                            "แจ้งเตือน",
                            "กรุณากรอกเบอร์โทรศัพท์"
                          )
                          return
                        } else if (isNaN(this.state.phone)) {
                          this.alert(
                            "info",
                            "แจ้งเตือน",
                            "รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง"
                          )
                          return
                        } else if (this.state.phone.trim().length != 10) {
                          this.alert(
                            "info",
                            "แจ้งเตือน",
                            "รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง"
                          )
                          return
                        } else if (this.state.project.trim() === "") {
                          this.alert("info", "แจ้งเตือน", "กรุณาเลือกโครงการ")
                          return
                        } else if (this.state.agency.trim() === "") {
                          this.alert("info", "แจ้งเตือน", "กรุณาเลือกหน่วยงาน")
                          return
                        } else if (this.state.role.trim() === "") {
                          this.alert("info", "แจ้งเตือน", "กรุณาเลือกบทบาท")
                          return
                        }
                        this.Edit()
                      }}
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* modal edit project */}
        <div
          className={
            this.state.modal_edit_project ? "show-modal" : "hide-modal"
          }
        >
          <div
            className="w-100 h-100 position-absolute"
            onClick={() => {
              this.clear_form_edit()
            }}
          ></div>
          <div
            className={
              this.state.modal_edit_project
                ? "box-modal p-4"
                : "hide-box-modal p-4"
            }
          >
            <div className="d-flex justify-content-between">
              <h3>
                <b>โครงการที่จัดการได้</b>
              </h3>
              <button
                className="btn btn-link d-block d-md-none"
                onClick={() => {
                  this.clear_form_edit()
                }}
              >
                <span className="icon mr-2 text-24">{"\uf00d"}</span>
              </button>
            </div>
            {this.state.agency_list &&
              this.state.agency_list.map((agency, index) => (
                <>
                  <hr />
                  {/* โครงการ */}
                  <div className="row mt-3">
                    <div className="col-12 col-sm-3 col-md-3 col-xl-3 col-lg-3">
                      <span>โครงการ:</span>
                    </div>
                    <div className="col-12 col-sm-9 col-md-9 col-xl-9 col-lg-9">
                      <select
                        className="form-control"
                        onChange={(e) => {
                          this.get_Agency(e.target.value, index + 1)
                        }}
                        value={agency.project_id && agency.project_id}
                      >
                        <option value="">-- เลือกโครงการ --</option>
                        {this.state.project_all_list.map((project, index) => (
                          <option value={project.project_id}>
                            {project.project_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {/* หน่วยงาน */}
                  <div className="row mt-3">
                    <div className="col-12 col-sm-3 col-md-3 col-xl-3 col-lg-3">
                      <span>หน่วยงาน:</span>
                    </div>
                    <div className="col-12 col-sm-9 col-md-9 col-xl-9 col-lg-9">
                      <select
                        className="form-control"
                        onChange={(e) => {
                          let agency_list = this.state.agency_list
                          agency_list[index] = e.target.value
                          this.setState({ agency_list: agency_list })
                        }}
                        value={agency.agency_id}
                      >
                        <option value="">-- เลือกหน่วย --</option>
                        {this.state.agency_all_list &&
                          this.state.agency_all_list[index + 1] &&
                          this.state.agency_all_list[index + 1].map(
                            (agency_all, index) => (
                              <option value={agency_all.agency_id}>
                                {agency_all.agency_name}
                              </option>
                            )
                          )}
                      </select>
                    </div>
                  </div>
                  <div className="w-100 text-right mt-3">
                    <button
                      className="btn btn-danger"
                      onClick={() => {
                        let agency_list = this.state.agency_list
                        agency_list.splice(index, 1)
                        this.setState({ agency_list: agency_list })
                        let agency_all_list = this.state.agency_all_list
                        agency_all_list.splice(index + 1, 1)
                        this.setState({ agency_all_list: agency_all_list })
                      }}
                    >
                      <span className="icon mr-2 text-18">{"\uf1f8"}</span>
                      ลบโครงการ
                    </button>
                  </div>
                </>
              ))}
            <hr />
            {/* เพิ่มโครงการ */}
            <div className="w-100 text-right mt-3">
              <button
                className="btn btn-outline-primary"
                onClick={() => {
                  let agency_list = this.state.agency_list
                  agency_list.push("")
                  this.setState({ agency_list: agency_list })
                }}
              >
                + เพิ่มโครงการ
              </button>
            </div>

            <hr />
            {/* ปุ่ม */}
            <div className="row">
              <div className="col-12 col-sm-9 col-md-9 col-xl-9 col-lg-9 ml-auto">
                <div className="row ">
                  <div className="col-12 col-sm-6 col-md-6 col-xl-6 col-lg-6 mt-3">
                    <button
                      className="btn btn-secondary w-100"
                      onClick={() => {
                        this.clear_form_edit()
                      }}
                    >
                      ยกเลิก
                    </button>
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-xl-6 col-lg-6 mt-3">
                    <button
                      className="btn btn-primary w-100"
                      onClick={() => {
                        this.Edit()
                      }}
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* modal edit password */}
        <div
          className={
            this.state.modal_edit_password ? "show-modal" : "hide-modal"
          }
        >
          <div
            className="w-100 h-100 position-absolute"
            onClick={() => {
              this.clear_form_edit()
            }}
          ></div>
          <div
            className={
              this.state.modal_edit_password
                ? "box-modal p-4"
                : "hide-box-modal p-4"
            }
          >
            <div className="d-flex justify-content-between">
              <h3>
                <b>แก้ไขรหัสผ่าน</b>
              </h3>
              <button
                className="btn btn-link d-block d-md-none"
                onClick={() => {
                  this.clear_form_edit()
                }}
              >
                <span className="icon mr-2 text-24">{"\uf00d"}</span>
              </button>
            </div>
            {/* Username */}
            <div className="row mt-4">
              <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                <span>Username:</span>
              </div>
              <div className="col-12 col-sm-12 col-md-9 col-xl-9 col-lg-9">
                <input
                  type="text"
                  className="form-control"
                  readOnly
                  value={this.state.username}
                ></input>
              </div>
            </div>
            {/* สุ่มรหัสผ่าน */}
            <div className="row mt-4">
              <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                <span>สุ่มรหัสผ่าน:</span>
              </div>
              <div className="col-12 col-sm-12 col-md-9 col-xl-9 col-lg-9">
                {this.state.password === "" ? (
                  <button
                    className="card w-100 p-3 align-items-center text-center bg-gray"
                    onClick={() => {
                      this.gen_Password()
                    }}
                  >
                    <h5>
                      <b className="text-primary">
                        คลิกที่นี่เพื่อสุ่มรหัสผ่าน
                      </b>
                    </h5>
                    <span className="text-gray">คัดลอกรหัสผ่านอัตโนมัติ</span>
                  </button>
                ) : (
                  <input
                    type="text"
                    className="form-control"
                    readOnly
                    onChange={(e) => {
                      this.setState({ password: e.target.value })
                    }}
                    value={this.state.password}
                  ></input>
                )}
              </div>
            </div>
            <hr />
            {/* ปุ่ม */}
            <div className="row">
              <div className="col-12 col-sm-12 col-md-9 col-xl-9 col-lg-9 ml-auto">
                <div className="row ">
                  <div className="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 mt-3">
                    <button
                      className="btn btn-secondary w-100"
                      onClick={() => {
                        this.clear_form_edit()
                      }}
                    >
                      ยกเลิก
                    </button>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 mt-3">
                    <button
                      className="btn btn-primary w-100"
                      onClick={() => {
                        this.EditPassword()
                      }}
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
