import React from "react";
import { Router, Route } from "react-router";
import App from "./components/App/index";
import Loading from "./components/Loading/Loading";
import Login from "./components/Login/Login";
import Register from "./components/Register/Register";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import NotFound from "./components/NotFound/Notfound";
import InAndOutWard from "./components/InAndOutWard/InAndOutWard";
import InternalParcel from "./components/InternalParcel/InternalParcel";
import ExternalParcel from "./components/ExternalParcel/ExternalParcel";
import Carrying from "./components/Carrying/Carrying";
import InAndOutList from "./components/InAndOut/InAndOutList/InAndOutList";
import CardType from "./components/InAndOut/CardType/CardType";
import Admin from "./components/Admin/Admin/Admin";
import RoleWeb from "./components/Admin/Role-web/Role-web";
import RoleMobile from "./components/Admin/Role-mobile/Role-mobile";
import User from "./components/User/User";
import "./global.js";

const Routes = (props) => (
  <Router {...props}>
    <Route path="/index" component={App} />
    <Route path="/" component={Loading} />
    <Route path="/login" component={Login} />
    <Route path="/register" component={Register} />
    <Route path="/forgot-password" component={ForgotPassword} />
    <Route path="/in-and-out-ward" component={InAndOutWard} />
    <Route path="/internal-parcel" component={InternalParcel} />
    <Route path="/external-parcel" component={ExternalParcel} />
    <Route path="/carrying" component={Carrying} />
    <Route path="/in-and-out-list" component={InAndOutList} />
    <Route path="/card-type" component={CardType} />
    <Route path="/admin" component={Admin} />
    <Route path="/role-web" component={RoleWeb} />
    <Route path="/role-mobile" component={RoleMobile} />
    <Route path="/user" component={User} />
    <Route path="*" component={NotFound} />
  </Router>
);

export default Routes;
