// LIBRARY
import Cookies from "universal-cookie";
import React, { Component } from "react";

// CSS
import "./style.css";
import "../../bootstrap.css";

const cookies = new Cookies();

export default class LoadingScreen extends Component {
  componentDidMount() {
    let token = cookies.get("token");
    if (token) {
      window.location.href = "/in-and-out-ward";
    } else {
      window.location.href = "/login";
    }
  }

  render() {
    return <div></div>;
  }
}
