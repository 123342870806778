// JS
import "../../../global.js"

// COMPONENT
import HeaderComponent from "../../Header/Header"
import MenuComponent from "../../SideMenu/SideMenu"
import BreadcrumbComponent from "../../Breadcrumb/Breadcrumb"

// LIBRARY
import Swal from "sweetalert2"
import { toast } from "react-toastify"
import Cookies from "universal-cookie"
import React, { Component } from "react"
import ReactLoading from "react-loading"
import Pagination from "react-js-pagination"

// CSS
import "./style.css"
import "../../../bootstrap.css"

const cookies = new Cookies()
toast.configure()

export default class RoleMobileScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      modal_add: false,
      modal_edit: false,

      token: cookies.get("token") ? "token " + cookies.get("token") : "",

      data: null,
      page: 1,

      roleid: "",
      rolename: "",
      role: {
        role_th: [
          "จัดการจดหมายและพัสดุ",
          "จัดการพัสดุภายนอก",
          "จัดการเข้า-ออกพื้นที่",
          "จัดการเข้า-ออกวอร์ด",
          "จัดการขนของเข้า-ออก",
        ],
        role_en: [
          "internal_package",
          "external_package",
          "in_out_area",
          "in_out_ward",
          "move_things",
        ],
        role_select: [false, false, false, false, false],
      },
    }
  }

  componentDidMount() {
    this.get_Role_Mobile()
  }

  alert(icon, title, detail) {
    Swal.fire({
      icon: icon,
      title: title,
      text: detail,
      confirmButtonText: "ตกลง",
    })
  }

  handlePageChange(pageNumber) {
    this.setState({ page: pageNumber })
    setTimeout(() => {
      this.get_Role_Mobile()
    }, 1)
  }

  clear_form() {
    this.setState({
      modal_add: false,
      modal_edit: false,
      roleid: "",
      rolename: "",
      role: {
        role_th: [
          "จัดการจดหมายและพัสดุ",
          "จัดการพัสดุภายนอก",
          "จัดการเข้า-ออกพื้นที่",
          "จัดการเข้า-ออกวอร์ด",
          "จัดการขนของเข้า-ออก",
        ],
        role_en: [
          "internal_package",
          "external_package",
          "in_out_area",
          "in_out_ward",
          "move_things",
        ],
        role_select: [false, false, false, false, false],
      },
    })
  }

  getEdit(role) {
    var detail = this.state.role
    for (let i = 0; i < detail.role_th.length; i++) {
      const en = detail.role_en[i]
      if (role.role_detail[en] === true || role.role_detail[en] === "true") {
        detail.role_select[i] = true
      }
    }

    this.setState({
      roleid: role.role_id,
      rolename: role.role_name,
      role: detail,
      modal_edit: true,
    })
  }

  get_Role_Mobile() {
    this.setState({ loading: true })
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
    }
    fetch(
      global.api + "v1/user/role/mobile/page/" + this.state.page,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          let des = ""
          var detail = this.state.role
          for (let role of result.data.data) {
            for (let i = 0; i < detail.role_th.length; i++) {
              const en = detail.role_en[i]
              if (
                role.role_detail[en] === true ||
                role.role_detail[en] === "true"
              ) {
                des += detail.role_th[i].replace("จัดการ", "") + " , "
              }
            }
            role.role_description = des.slice(0, -2)
            des = ""
          }
          // role_description
          this.setState({ data: result.data })
          this.setState({ loading: false })
        } else {
          switch (result.data.status) {
            case "This Page is not available":
              this.setState({ data: [] })
              break
            default:
              this.alert("info", "แจ้งเตือน", "เซสชันหมดอายุ")
              cookies.remove("token")
              window.location.href = "/"
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.setState({ loading: false })
      })
  }

  Create = () => {
    this.setState({ loading: true })
    var role_detail = {}

    for (let i = 0; i < this.state.role.role_th.length; i++) {
      role_detail[this.state.role.role_en[i]] =
        this.state.role.role_select[i] === true ? "true" : "false"
    }
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
      body: JSON.stringify({
        role_name: this.state.rolename,
        role_device: "mobile",
        role_detail: role_detail,
      }),
    }
    fetch(global.api + "v1/user/role/create", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.clear_form()
          this.alert("success", "สำเร็จ", "เพิ่มบทบาทสำหรับโมบายแอปแล้ว")
          this.setState({ page: 1 })
          this.get_Role_Mobile()
        } else {
          switch (result.data.message) {
            case "Data role name is none":
              this.alert("info", "แจ้งเตือน", "กรุณากรอกชื่อบทบาท")
              break
            case "This role name is existed":
              this.alert("info", "แจ้งเตือน", "มีชื่อบทบาทนี้ในระบบแล้ว")
              break
            case "This role name is duplicate":
              this.alert("info", "แจ้งเตือน", "มีชื่อบทบาทนี้ในระบบแล้ว")
              break
            case "This Page is not available":
              this.alert("info", "แจ้งเตือน", "ไม่พบหน้า")
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.setState({ loading: false })
      })
  }

  Edit = () => {
    this.setState({ loading: true })
    var role_detail = {}
    for (let i = 0; i < this.state.role.role_th.length; i++) {
      role_detail[this.state.role.role_en[i]] =
        this.state.role.role_select[i] === true ? "true" : "false"
    }

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: this.state.token,
      },
      body: JSON.stringify({
        role_id: this.state.roleid,
        role_name: this.state.rolename,
        role_detail: role_detail,
      }),
    }
    fetch(global.api + "v1/user/role/update", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.clear_form()
          this.alert("success", "สำเร็จ", "แก้ไขบทบาทสำหรับโมบายแอปแล้ว")
          this.setState({ page: 1 })
          this.get_Role_Mobile()
        } else {
          switch (result.data.message) {
            case "Data role name is none":
              this.alert("info", "แจ้งเตือน", "กรุณากรอกชื่อบทบาท")
              break
            case "This role name is existed":
              this.alert("info", "แจ้งเตือน", "มีชื่อบทบาทนี้ในระบบแล้ว")
              break
            case "This role name is duplicate":
              this.alert("info", "แจ้งเตือน", "มีชื่อบทบาทนี้ในระบบแล้ว")
              break
            default:
              this.alert("info", "แจ้งเตือน", "เกิดข้อผิดพลาด")
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        console.log(e)
        this.setState({ loading: false })
      })
  }

  Delete = (role) => {
    Swal.fire({
      icon: "info",
      title: "ลบบทบาท",
      text: "ท่านแน่ใจว่าต้องการลบบทบาท “" + role.role_name + "”",
      showCancelButton: true,
      confirmButtonText: "ลบข้อมูล",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.isConfirmed) {
        const requestOptions = {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
            Authorization: this.state.token,
          },
          accept: "application/json",
        }
        fetch(
          global.api + "v1/user/role/delete/" + role.role_id,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            if (result.status) {
              this.setState({ loading: false, page: 1 })
              this.alert("success", "แจ้งเตือน", "ลบบทบาทสำเร็จ")
              this.get_Role_Mobile()
            } else {
              switch (result.data.message) {
                case "":
                  break
                default:
                  this.alert("info", "แจ้งเตือน", "เซสชันหมดอายุ")
                  cookies.remove("token")
                  window.location.href = "/"
                  break
              }
              this.setState({ loading: false })
            }
          })
          .catch((e) => {
            this.setState({ loading: false })
          })
      }
    })
  }

  render() {
    return (
      <div className="full bg-contain">
        {this.state.loading && (
          <div className="loading">
            <ReactLoading
              type={"spinningBubbles"}
              color="#fff"
              height={48}
              width={48}
            />
          </div>
        )}
        <div className="menu-container">
          <MenuComponent />
          <div className="w-100">
            <BreadcrumbComponent page1="ผู้ดูแลระบบ" page2="บทบาทโมบายแอป" />
            <HeaderComponent header="บทบาทโมบายแอป" report="false" />
            {/* เมนู ฟิลเตอร์  */}
            <div className="bg-gray px-3 pt-3 w-100">
              <div className="row d-flex mx-0 justify-content-between">
                <div className="d-flex row mx-0"></div>
                <div className="d-flex row mx-0">
                  <div className="mx-2 mb-3">
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        this.setState({ modal_add: true })
                      }}
                    >
                      + เพิ่มบทบาท
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* ตาราง */}
            <div className="w-100 p-3">
              <div className="table-responsive">
                <table className="table design-table">
                  <thead>
                    <tr>
                      <th className="text-left w-150px">ชื่อบทบาท</th>
                      <th className="text-left ">รายละเอียด</th>
                      <th className="text-center  w-150px">จัดการ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data &&
                      this.state.data.data &&
                      this.state.data.data.map((role, index) => (
                        <tr key={index}>
                          <td>{role.role_name}</td>
                          <td>{role.role_description}</td>
                          <td className="text-center w-150px">
                            <div className="d-flex justify-content-center">
                              <button
                                className="btn btn-link"
                                onClick={() => {
                                  this.getEdit(role)
                                }}
                              >
                                <span className="icon mr-2">{"\uf304"}</span>
                              </button>
                              <button
                                className="btn btn-link text-danger"
                                onClick={() => {
                                  this.Delete(role)
                                }}
                              >
                                <span className="icon mr-2">{"\uf1f8"}</span>
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            {/* pagination */}
            {this.state.data && (
              <div className="d-flex justify-content-between row mx-0 w-100 px-3">
                <div>
                  <span>
                    แสดง {this.state.data.end_index} จาก {this.state.data.count}{" "}
                    รายการ
                  </span>
                </div>
                <div className="d-flex">
                  <button
                    className="btn-circle-white mx-1"
                    onClick={() => {
                      this.handlePageChange(1)
                    }}
                  >
                    <span className="icon">{"\uf100"}</span>
                  </button>
                  <Pagination
                    activePage={this.state.page}
                    itemsCountPerPage={10}
                    totalItemsCount={this.state.data.count}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange.bind(this)}
                    prevPageText={<span className="icon">{"\uf104"}</span>}
                    nextPageText={<span className="icon">{"\uf105"}</span>}
                    hideFirstLastPages={true}
                  />
                  <button
                    className="btn-circle-white mx-1"
                    onClick={() => {
                      this.handlePageChange(this.state.data.page_number)
                    }}
                  >
                    <span className="icon">{"\uf101"}</span>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* modal add */}
        <div className={this.state.modal_add ? "show-modal" : "hide-modal"}>
          <div
            className="w-100 h-100 position-absolute"
            onClick={() => {
              this.clear_form()
            }}
          ></div>
          <div
            className={
              this.state.modal_add ? "box-modal p-4" : "hide-box-modal p-4"
            }
          >
            <div className="d-flex justify-content-between">
              <h3>
                <b>เพิ่มบทบาท</b>
              </h3>
              <button
                className="btn btn-link d-block d-md-none"
                onClick={() => {
                  this.clear_form()
                }}
              >
                <span className="icon mr-2 text-24">{"\uf00d"}</span>
              </button>
            </div>
            {/* ชื่อบทบาท */}
            <div className="row mt-4">
              <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                <span>ชื่อบทบาท:</span>
              </div>
              <div className="col-12 col-sm-12 col-md-9 col-xl-9 col-lg-9">
                <input
                  type="text"
                  className="form-control"
                  placeholder="เช่น จัดการผู้ใช้งาน"
                  onChange={(e) => {
                    this.setState({ rolename: e.target.value })
                  }}
                  value={this.state.rolename}
                ></input>
              </div>
            </div>
            {/* ชื่อบทบาท */}
            <div className="row mt-4">
              {this.state.role.role_th.map((role, index) => (
                <div
                  key={index}
                  className="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 mb-3"
                >
                  <button
                    className={
                      this.state.role.role_select[index]
                        ? "btn btn-primary d-flex w-100 align-items-center "
                        : "btn btn-outline-primary d-flex w-100 align-items-center "
                    }
                    onClick={() => {
                      let role_data = this.state.role
                      role_data.role_select[index] =
                        !this.state.role.role_select[index]
                      this.setState({ role: role_data })
                    }}
                  >
                    <div className="select_checked_out mr-2">
                      <div className="select_checked ">
                        <div className="select_checked_in"></div>
                      </div>
                    </div>
                    {role}
                  </button>
                </div>
              ))}
            </div>
            <hr />
            {/* ปุ่ม */}
            <div className="row">
              <div className="col-12 col-sm-12 col-md-9 col-xl-9 col-lg-9 ml-auto">
                <div className="row ">
                  <div className="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 mt-3">
                    <button
                      className="btn btn-secondary w-100"
                      onClick={() => {
                        this.clear_form()
                      }}
                    >
                      ยกเลิก
                    </button>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 mt-3">
                    <button
                      className="btn btn-primary w-100"
                      onClick={() => {
                        this.Create()
                      }}
                    >
                      เพิ่มบทบาท
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* modal edit */}
        <div className={this.state.modal_edit ? "show-modal" : "hide-modal"}>
          <div
            className="w-100 h-100 position-absolute"
            onClick={() => {
              this.clear_form()
            }}
          ></div>
          <div
            className={
              this.state.modal_edit ? "box-modal p-4" : "hide-box-modal p-4"
            }
          >
            <div className="d-flex justify-content-between">
              <h3>
                <b>แก้ไขบทบาท</b>
              </h3>
              <button
                className="btn btn-link d-block d-md-none"
                onClick={() => {
                  this.clear_form()
                }}
              >
                <span className="icon mr-2 text-24">{"\uf00d"}</span>
              </button>
            </div>
            {/* ชื่อบทบาท */}
            <div className="row mt-4">
              <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                <span>ชื่อบทบาท:</span>
              </div>
              <div className="col-12 col-sm-12 col-md-9 col-xl-9 col-lg-9">
                <input
                  type="text"
                  className="form-control"
                  placeholder="เช่น จัดการผู้ใช้งาน"
                  onChange={(e) => {
                    this.setState({ rolename: e.target.value })
                  }}
                  value={this.state.rolename}
                ></input>
              </div>
            </div>
            {/* ชื่อบทบาท */}
            <div className="row mt-4">
              {this.state.role.role_th.map((role, index) => (
                <div
                  key={index}
                  className="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 mb-3"
                >
                  <button
                    className={
                      this.state.role.role_select[index]
                        ? "btn btn-primary d-flex w-100 align-items-center "
                        : "btn btn-outline-primary d-flex w-100 align-items-center "
                    }
                    onClick={() => {
                      let role_data = this.state.role
                      role_data.role_select[index] =
                        !this.state.role.role_select[index]
                      this.setState({ role: role_data })
                    }}
                  >
                    <div className="select_checked_out mr-2">
                      <div className="select_checked ">
                        <div className="select_checked_in"></div>
                      </div>
                    </div>
                    {role}
                  </button>
                </div>
              ))}
            </div>
            <hr />
            {/* ปุ่ม */}
            <div className="row">
              <div className="col-12 col-sm-12 col-md-9 col-xl-9 col-lg-9 ml-auto">
                <div className="row ">
                  <div className="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 mt-3">
                    <button
                      className="btn btn-secondary w-100"
                      onClick={() => {
                        this.clear_form()
                      }}
                    >
                      ยกเลิก
                    </button>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 mt-3">
                    <button
                      className="btn btn-primary w-100"
                      onClick={() => {
                        this.Edit()
                      }}
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
