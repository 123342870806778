// LIBRARY
import { toast } from "react-toastify"
import React, { Component } from "react"
import Cookies from "universal-cookie"
import Swal from "sweetalert2"

// CSS
import "./style.css"
import "../../bootstrap.css"
import "react-toastify/dist/ReactToastify.css"
import "react-pro-sidebar/dist/css/styles.css"
import "@trendmicro/react-sidenav/dist/react-sidenav.css"

const cookies = new Cookies()
toast.configure()

export default class HeaderComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modal_in_and_out_ward: false,
      modal_internal_parcel: false,
      modal_external_parcel: false,
      modal_in_and_out: false,
      modal_carrying: false,
      start_date: "",
      end_date: "",
      type: "พัสดุธรรมดา",
      project: "",
      agency: "",
      project_all_list: cookies.get("user_profile").agency_list
        ? cookies.get("user_profile").agency_list
        : null,
      agency_all_list: [],
      token: cookies.get("token") ? cookies.get("token") : "",
    }
  }

  componentDidMount() {
    var user = cookies.get("user_profile")
    if (user.role_data.role_name === "system_admin") {
      this.get_Project()
    } else {
      this.state.project_all_list.push({
        agency_id: cookies.get("user_profile").agency_id,
        agency_name: cookies.get("user_profile").agency_name,
        project_id: cookies.get("user_profile").project_id,
        project_name: cookies.get("user_profile").project_name,
      })
    }
  }

  alert(icon, title, detail) {
    Swal.fire({
      icon: icon,
      title: title,
      text: detail,
      confirmButtonText: "ตกลง",
    })
  }

  clear_form() {
    this.setState({
      modal_in_and_out_ward: false,
      modal_internal_parcel: false,
      modal_external_parcel: false,
      modal_in_and_out: false,
      modal_carrying: false,
      start_date: "",
      end_date: "",
      type: "พัสดุธรรมดา",
      project: "",
      agency: "",
    })
  }

  in_and_out_ward() {
    if (
      this.state.token &&
      this.state.start_date &&
      this.state.end_date &&
      this.state.project &&
      this.state.agency
    ) {
      window.open(
        global.api +
          "v1/in-out-ward/on-web/export/" +
          this.state.token +
          "/" +
          this.state.start_date +
          "/" +
          this.state.end_date +
          "/" +
          this.state.project +
          "/" +
          this.state.agency,
        "_blank"
      )
      this.clear_form()
    } else {
      this.alert("info", "แจ้งเตือน", "กรุณากรอกข้อมูลให้ครบถ้วน")
    }
  }
  internal_parcel() {
    if (
      this.state.token &&
      this.state.start_date &&
      this.state.end_date &&
      this.state.project &&
      this.state.agency
    ) {
      window.open(
        global.api +
          "v1/internal-package/export/" +
          this.state.token +
          "/" +
          this.state.start_date +
          "/" +
          this.state.end_date +
          "/" +
          this.state.project +
          "/" +
          this.state.agency,
        "_blank"
      )
      this.clear_form()
    } else {
      this.alert("info", "แจ้งเตือน", "กรุณากรอกข้อมูลให้ครบถ้วน")
    }
  }
  external_parcel() {
    if (
      this.state.token &&
      this.state.start_date &&
      this.state.end_date &&
      this.state.project &&
      this.state.agency
    ) {
      window.open(
        global.api +
          "v1/external-package/export/" +
          this.state.token +
          "/" +
          this.state.start_date +
          "/" +
          this.state.end_date +
          "/" +
          this.state.project +
          "/" +
          this.state.agency,
        "_blank"
      )
      this.clear_form()
    } else {
      this.alert("info", "แจ้งเตือน", "กรุณากรอกข้อมูลให้ครบถ้วน")
    }
  }
  in_and_out() {
    if (
      this.state.token &&
      this.state.start_date &&
      this.state.end_date &&
      this.state.project &&
      this.state.agency
    ) {
      window.open(
        global.api +
          "v1/in-out-area/on-web/export/" +
          this.state.token +
          "/" +
          this.state.start_date +
          "/" +
          this.state.end_date +
          "/" +
          this.state.project +
          "/" +
          this.state.agency,
        "_blank"
      )
      this.clear_form()
    } else {
      this.alert("info", "แจ้งเตือน", "กรุณากรอกข้อมูลให้ครบถ้วน")
    }
  }
  carrying() {
    if (
      this.state.token &&
      this.state.start_date &&
      this.state.end_date &&
      this.state.project &&
      this.state.agency
    ) {
      window.open(
        global.api +
          "v1/move-things/on-web/export/" +
          this.state.token +
          "/" +
          this.state.start_date +
          "/" +
          this.state.end_date +
          "/" +
          this.state.project +
          "/" +
          this.state.agency,
        "_blank"
      )
      this.clear_form()
    } else {
      this.alert("info", "แจ้งเตือน", "กรุณากรอกข้อมูลให้ครบถ้วน")
    }
  }
  get_Project() {
    this.setState({ loading: true })
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    }
    fetch(global.api + "v1/project/all", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ project_all_list: result.data.data })

          this.setState({ loading: false })
        } else {
          switch (result.data.detail) {
            case "":
              break
            default:
              this.alert("info", "แจ้งเตือน", "เซสชันหมดอายุ")
              cookies.remove("token")
              window.location.href = "/"
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.setState({ loading: false })
      })
  }
  get_Agency(id) {
    this.setState({ loading: true })
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        project_id: id,
      }),
    }
    fetch(global.api + "v1/project/agency", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          this.setState({ agency_all_list: result.data.data })
          this.setState({ loading: false })
        } else {
          switch (result.data.detail) {
            case "":
              break
            default:
              this.alert("info", "แจ้งเตือน", "เซสชันหมดอายุ")
              cookies.remove("token")
              window.location.href = "/"
              break
          }
          this.setState({ loading: false })
        }
      })
      .catch((e) => {
        this.setState({ loading: false })
      })
  }

  render() {
    return (
      <div className="w-100 d-flex justify-content-between p-4 mt-5 ">
        <h2 className="mt-4 w-100 ">
          <b>{this.props.header}</b>
        </h2>
        {this.props.report === "true" && (
          <button
            className="btn btn-primary px-3 "
            onClick={() => {
              this.clear_form()
              switch (this.props.header) {
                case "เข้า-ออกวอร์ด":
                  this.setState({ modal_in_and_out_ward: true })
                  break

                case "จดหมายและพัสดุ":
                  this.setState({ modal_internal_parcel: true })
                  break

                case "พัสดุภายนอก":
                  this.setState({ modal_external_parcel: true })
                  break

                case "เข้า-ออกพื้นที่":
                  this.setState({ modal_in_and_out: true })
                  break

                case "ขนของเข้า-ออก":
                  this.setState({ modal_carrying: true })
                  break

                default:
                  break
              }
              this.setState({ sidebar: !this.state.sidebar })
            }}
          >
            <span className="icon mr-2">{"\uf33d"}</span>ออกรายงาน
          </button>
        )}
        {/* modal */}
        <div
          className={
            this.state.modal_in_and_out_ward ||
            this.state.modal_internal_parcel ||
            this.state.modal_external_parcel ||
            this.state.modal_in_and_out ||
            this.state.modal_carrying
              ? "show-modal"
              : "hide-modal"
          }
        >
          <div
            className="w-100 h-100 position-absolute"
            onClick={() => {
              this.clear_form()
            }}
          ></div>
          <div
            className={
              this.state.modal_in_and_out_ward ||
              this.state.modal_internal_parcel ||
              this.state.modal_external_parcel ||
              this.state.modal_in_and_out ||
              this.state.modal_carrying
                ? "box-modal p-4"
                : "hide-box-modal p-4"
            }
          >
            <div className="d-flex justify-content-between">
              <h3>
                <b>ออกรายงาน</b>
              </h3>
              <button
                className="btn btn-link d-block d-md-none"
                onClick={() => {
                  this.clear_form()
                }}
              >
                <span className="icon mr-2 text-24">{"\uf00d"}</span>
              </button>
            </div>
            {/* วันที่เริ่มต้น */}
            <div className="row mt-4">
              <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                <span>วันที่เริ่มต้น:</span>
              </div>
              <div className="col-12 col-sm-12 col-md-9 col-xl-9 col-lg-9">
                <input
                  type="date"
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ start_date: e.target.value })
                  }}
                  value={this.state.start_date}
                ></input>
              </div>
            </div>
            {/* วันที่สิ้นสุด */}
            <div className="row mt-3">
              <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                <span>วันที่สิ้นสุด:</span>
              </div>
              <div className="col-12 col-sm-12 col-md-9 col-xl-9 col-lg-9">
                <input
                  type="date"
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ end_date: e.target.value })
                  }}
                  value={this.state.end_date}
                ></input>
              </div>
            </div>
            <hr />
            {/* ประเภท */}
            {/* {this.props.header === "พัสดุภายนอก" && (
              <>
                <div className="row mt-3">
                  <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                    <span>ประเภท:</span>
                  </div>
                  <div className="col-12 col-sm-12 col-md-9 col-xl-9 col-lg-9">
                    <div className="card p-1">
                      <div className="row w-100 mx-0">
                        <div className="col-6 px-1">
                          <button
                            className={
                              this.state.type === "พัสดุธรรมดา"
                                ? "btn btn-primary w-100 px-0"
                                : "btn bg-white w-100 px-0"
                            }
                            onClick={(e) => {
                              this.setState({ type: "พัสดุธรรมดา" });
                            }}
                          >
                            พัสดุธรรมดา
                          </button>
                        </div>
                        <div className="col-6 px-1">
                          <button
                            className={
                              this.state.type === "พัสดุมีเลขทะเบียน"
                                ? "btn btn-primary w-100 px-0"
                                : "btn bg-white w-100 px-0"
                            }
                            onClick={(e) => {
                              this.setState({ type: "พัสดุมีเลขทะเบียน" });
                            }}
                          >
                            พัสดุมีเลขทะเบียน
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
              </>
            )} */}
            {/* โครงการ */}
            <div className="row mt-3">
              <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                <span>โครงการ:</span>
              </div>
              <div className="col-12 col-sm-12 col-md-9 col-xl-9 col-lg-9">
                <select
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ project: e.target.value })
                    if (e.target.value === "") {
                      this.setState({ agency_all_list: [], project: "" })
                    } else {
                      this.get_Agency(e.target.value)
                    }
                  }}
                  value={this.state.project}
                >
                  <option value="">-- เลือกโครงการ --</option>
                  {this.state.project_all_list &&
                    this.state.project_all_list.map((project, index) => (
                      <option key={index} value={project.project_id}>
                        {project.project_name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            {/* หน่วยงาน */}
            <div className="row mt-3">
              <div className="col-12 col-sm-12 col-md-3 col-xl-3 col-lg-3">
                <span>หน่วยงาน:</span>
              </div>
              <div className="col-12 col-sm-12 col-md-9 col-xl-9 col-lg-9">
                <select
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ agency: e.target.value })
                  }}
                  value={this.state.agency}
                >
                  <option value="">-- เลือกหน่วย --</option>
                  {this.state.agency_all_list &&
                    this.state.agency_all_list.map((agency, index) => (
                      <option value={agency.agency_id}>
                        {agency.agency_name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <hr />
            {/* ปุ่ม */}
            <div className="row">
              <div className="col-12 col-sm-12 col-md-9 col-xl-9 col-lg-9 ml-auto">
                <div className="row ">
                  <div className="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 mt-3">
                    <button
                      className="btn btn-secondary w-100"
                      onClick={() => {
                        this.clear_form()
                      }}
                    >
                      ยกเลิก
                    </button>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 mt-3">
                    <button
                      className="btn btn-primary w-100"
                      onClick={() => {
                        switch (this.props.header) {
                          case "เข้า-ออกวอร์ด":
                            this.in_and_out_ward()
                            break

                          case "จดหมายและพัสดุ":
                            this.internal_parcel()
                            break

                          case "พัสดุภายนอก":
                            this.external_parcel()
                            break

                          case "เข้า-ออกพื้นที่":
                            this.in_and_out()
                            break

                          case "ขนของเข้า-ออก":
                            this.carrying()
                            break

                          default:
                            break
                        }
                      }}
                    >
                      <span className="icon mr-2">{"\uf063"}</span>ดาวน์โหลด
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
