import "./style.css";
import "../../bootstrap.css";
import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class AppScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div className="full-screen center bg-secondary">
        <div className="col-xl-3 col-lg-2 col-md-3 col-sm-2 col-1" />
        <div className="col-xl-6 col-lg-8 col-md-6 col-sm-8 col-10">
          <div className=" d-none d-sm-none d-md-none d-lg-block d-xl-block">
            <div className="card-group ">
              <div className="card ">
                <div className="card-body p-5">
                  <h3 className="card-title">Login</h3>
                  <p className="card-text">
                    <small className="text-muted">
                      Sign In to your account
                    </small>
                  </p>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">
                        <span className="icon">{"\uf007"}</span>
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Username"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                  </div>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">
                        <span className="icon">{"\uf30d"}</span>
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Password"
                      aria-label="Password"
                      aria-describedby="basic-addon1"
                    />
                  </div>
                  <div className="row mt-4">
                    <div className="col-5">
                      <button type="button" className="btn btn-primary">
                        Login
                      </button>
                    </div>
                    <div className="col-7 text-right">
                      <button type="button" className="btn btn-link">
                        Forgot password?
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body p-5">
                  <h3 className="card-title text-center">Sign up</h3>
                  <p className="card-text text-center">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                  <div className="row mt-5">
                    <div className="col-12 text-center">
                      <button type="button" className="btn btn-primary">
                        Register Now!
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-block d-lg-none d-xl-none">
            <div className="card ">
              <div className="card-body p-5">
                <h3 className="card-title">Login</h3>
                <p className="card-text">
                  <small className="text-muted">Sign In to your account</small>
                </p>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      <span className="icon">{"\uf007"}</span>
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Username"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                </div>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      <span className="icon">{"\uf30d"}</span>
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Password"
                    aria-label="Password"
                    aria-describedby="basic-addon1"
                  />
                </div>
                <div className="row">
                  <div className="col-12 col-sm-5">
                    <button type="button" className="btn btn-primary">
                      Login
                    </button>
                  </div>
                  <div className="col-12 col-sm-7 text-right d-none  d-sm-block">
                    <button type="button" className="btn btn-link">
                      Forgot password?
                    </button>
                  </div>
                  <div className="d-block d-sm-none col-12 col-sm-7">
                    <button type="button" className="btn btn-link p-0 mt-3">
                      Forgot password?
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container bg-light">
            <div className="row p-4">
              <div className="col-md-7 col-sm-7 col-12">
                <button
                  type="button"
                  className="btn btn-facebook p-2 social mr-1"
                >
                  <span className="icon-brand">{"\uf39e"}</span>
                </button>
                <button
                  type="button"
                  className="btn btn-google p-2 social mr-1"
                >
                  <span className="icon-brand">{"\uf1a0"}</span>
                </button>
                <button type="button" className="btn btn-line p-2 social">
                  {/* <img className="icon-brand" src={LINE} /> */}
                  {/* <LINE /> */}
                </button>
              </div>
              <div className="col-md-5 col-sm-5 col-12 text-right d-none d-sm-block d-md-block d-lg-none d-xl-none">
                <Link to="/register" className="button">
                  <button type="button" className="btn btn-primary">
                    Register Now!
                  </button>
                </Link>
              </div>
              <div className="col-md-5 col-sm-5 col-12 d-block d-sm-none d-md-none d-lg-none d-xl-none mt-3">
                <button type="button" className="btn btn-primary">
                  Register Now!
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-2 col-md-3 col-sm-2 col-1" />
      </div>
    );
  }
}
